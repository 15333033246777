define("commander/routes/login", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    model() {
      return Ember.Object.create({
        map: Ember.Object.create({
          initLat: _environment.default.APP.leaflet.mapOptions.center[0],
          initLng: _environment.default.APP.leaflet.mapOptions.center[1],
          zoom: _environment.default.APP.leaflet.mapOptions.zoom,
          zoomControl: _environment.default.APP.leaflet.mapOptions.zoomControl,
          tomtom: _environment.default.APP.leaflet.tomtom,
          tileLayer: Ember.Object.create({
            url: _environment.default.APP.leaflet.tileLayer.url,
            key: _environment.default.APP.leaflet.mapOptions.key
          })
        }),
        customerStyle: _environment.default.APP.customerStyle
      });
    },
    actions: {
      login() {
        this.auth.login();
      }
    }
  });
});