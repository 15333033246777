define("commander/pods/index/shifts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YvVYfI3p",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"shifts-panel container-fluid card card-inverse\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"card-title\"],[12],[1,[30,[36,0],[\"shifts.title\"],null]],[13],[2,\"\\n\\n    \"],[11,\"span\"],[24,0,\"close-button\"],[4,[38,1],[[32,0],\"close\"],null],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"icon\",\"size\"],[\"times\",\"lg\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n    \"],[1,[30,[36,4],null,[[\"shifts\",\"cancelNew\",\"removeShift\",\"vehicleClicked\"],[[35,3],\"cancelNewShift\",\"removeShift\",\"vehicleClicked\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"fa-icon\",\"shifts\",\"shifts-table\"]}",
    "meta": {
      "moduleName": "commander/pods/index/shifts/template.hbs"
    }
  });
});