define("commander/services/messenger", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    messenger: null,
    enabled: _environment.default.APP.ui.enableMessaging,
    openMessenger(vehicle) {
      if (this.messenger) {
        this.messenger.openMessenger(vehicle);
      }
    },
    setMessenger(messenger) {
      this.set('messenger', messenger);
    }
  });
});