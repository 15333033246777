define("commander/pods/components/break-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dbr/hp/G",
    "block": "{\"symbols\":[\"break\",\"index\"],\"statements\":[[10,\"table\"],[14,0,\"table table-sm table-hover\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[8,\"sortable-th\",[],[[\"@width\",\"@sortBy\",\"@sortType\",\"@class\",\"@sort\"],[\"40%\",\"periodStart\",\"date\",\"header-col-sent\",[30,[36,2],[[32,0],\"sort\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,3],[\"breakList.start\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,\"sortable-th\",[],[[\"@width\",\"@sortBy\",\"@sortType\",\"@class\",\"@sort\"],[\"40%\",\"periodEnd\",\"date\",\"header-col-sent\",[30,[36,2],[[32,0],\"sort\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,3],[\"breakList.end\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[10,\"th\"],[14,5,\"width:10%\"],[12],[2,\"\\n                \"],[1,[30,[36,3],[\"breakList.duration\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"th\"],[14,5,\"width:10%\"],[12],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],null,[[\"rowIndex\",\"vehicleNumber\",\"break\",\"cancelNew\",\"removeBreak\"],[[32,2],[35,0],[32,1],\"cancelNew\",\"removeBreak\"]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"vehicleNumber\",\"break-row\",\"action\",\"t\",\"sorted\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "commander/pods/components/break-table/template.hbs"
    }
  });
});