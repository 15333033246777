define("commander/pods/components/daterange-picker-inputs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ptS4p1OQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"class\",\"allowInput\",\"date\",\"onChange\",\"defaultDate\",\"locale\",\"static\",\"onReady\"],[\"form-control form-control-sm col-8\",true,[35,2],[30,[36,0],[[32,0],\"onChange\",\"date\"],null],[35,2],[35,1],true,[30,[36,0],[[32,0],\"onReady\"],null]]]]],[2,\"\\n\"],[1,[30,[36,3],null,[[\"class\",\"enableTime\",\"noCalendar\",\"allowInput\",\"time_24hr\",\"date\",\"onChange\",\"defaultDate\",\"locale\",\"static\",\"onReady\"],[\"form-control form-control-sm col-4\",true,true,true,true,[35,2],[30,[36,0],[[32,0],\"onChange\",\"time\"],null],[35,2],[35,1],true,[30,[36,0],[[32,0],\"onReady\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"locale\",\"date\",\"ember-flatpickr\"]}",
    "meta": {
      "moduleName": "commander/pods/components/daterange-picker-inputs/template.hbs"
    }
  });
});