define("commander/services/ui-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    playAudio(audioFile = '/audio/ding_ding_sms.mp3') {
      try {
        let audio = new Audio(audioFile);
        audio.play();
      } catch (e) {
        console.log('Cannot play audio', e);
      }
    },
    showDesktopNotification(title, callback) {
      try {
        if (!('Notification' in window)) {
          // This browser does not support desktop notification
        }
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === 'granted') {
          let notification = new Notification(title);
          if (callback) {
            notification.onclick = callback;
          }
        }
        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
          Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
              let notification = new Notification(title);
              if (callback) {
                notification.onclick = callback;
              }
            }
          });
        }
      } catch (e) {
        console.log('Cannot show desktop notification', e);
      }
    }
  });
});