define("commander/pods/index/settings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    settings: Ember.inject.service(),
    dataUpdateService: Ember.inject.service('data-update'),
    _model: Ember.Object.create({
      generateBreak: false,
      dataUpdate: null,
      events: null
    }),
    model() {
      this.set('_model.generateBreak', this.settings.getGenerateBreak());
      this.set('_model.dataUpdate', this.dataUpdateService);
      this.set('_model.events', this.get('settings.events'));
      return this._model;
    },
    _foo: Ember.observer('_model.generateBreak', function () {
      this.settings.setGenerateBreak(this.get('_model.generateBreak'));
    }),
    actions: {
      close() {
        this.transitionTo('index');
      },
      updateSettings() {
        this.settings.setSetting('events', this.get('_model.events'));
      }
    }
  });
});