define("commander/pods/components/shift-table/component", ["exports", "commander/mixins/data-sorter"], function (_exports, _dataSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_dataSorter.default, {
    sortDataProperty: 'shifts',
    actions: {
      cancelNew(shift) {
        this.sendAction('cancelNew', shift);
      },
      removeShift(shift) {
        this.sendAction('removeShift', shift);
      },
      shiftAdded(shift) {
        this.sendAction('shiftAdded', shift);
      }
    }
  });
});