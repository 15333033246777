define("commander/services/settings", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    generateBreak: false,
    events: {
      tripLate: {
        highlight: true,
        notify: false
      },
      vehiclePing: {
        highlight: false,
        notify: false
      },
      dispatchFail: {
        highlight: false,
        notify: false
      }
    },
    init() {
      this._super(...arguments);
      this.set('generateBreak', this._readBoolean('generateBreak', false));

      // try to sync the actual object as much as we can if has structural differences with the stored one
      let events = this.getSetting('events');
      if (!this._hasSameStructure(this.events, events)) {
        events = this._syncEventsSettings(this.events, events);
      }

      // rip out dispatch events if the appropriate setting is not set.
      if (!_environment.default.APP.dispatchEvents.listen && events.hasOwnProperty('dispatchFail')) {
        delete events.dispatchFail;
      }
      this.setSetting('events', events);
    },
    _hasSameStructure(o1, o2) {
      return o1 !== null && o2 !== null && typeof o1 === 'object' && typeof o2 === 'object' &&
      // check that both are objects
      Object.keys(o1).sort().join(',') === Object.keys(o2).sort().join(',') &&
      // check that both have the same set of fields
      Object.keys(o1).every(k => typeof o1[k] === 'object' ? this._hasSameStructure(o1[k], o2[k]) : o2.hasOwnProperty(k)); // go deeper for nested objects
    },
    _syncEventsSettings(actual, stored) {
      Object.keys(actual).forEach(k => {
        if (stored !== null && stored.hasOwnProperty(k)) {
          if (typeof actual[k] === 'object') {
            this._syncEventsSettings(actual[k], stored[k]);
          } else if (typeof stored[k] !== 'object') {
            actual[k] = stored[k];
          }
        }
      });
      return actual;
    },
    getGenerateBreak() {
      return this.generateBreak;
    },
    setGenerateBreak(val) {
      this.set('generateBreak', val);
      this.save();
    },
    save() {
      localStorage.setItem('generateBreak', this.generateBreak);
    },
    _readBoolean(key, defaultVal) {
      let val = localStorage.getItem(key);
      if (val === undefined || val === 'undefined' || val === null || val === 'null') {
        val = defaultVal;
      } else if (typeof val === 'string') {
        val = val === 'true';
      }
      return val;
    },
    getSetting(key) {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (e) {
        return null;
      }
    },
    setSetting(key, value) {
      this.set(key, value);
      localStorage.setItem(key, JSON.stringify(value));
    }
  });
});