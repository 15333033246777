define("commander/helpers/app-link", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appLink = appLink;
  _exports.default = void 0;
  /**
   * Url builder for other vinka app
   * @param {Array} params [0]=app name [1]=path to trip or route
   */
  function appLink(params) {
    const appName = params[0];
    const path = params[1];
    if (_environment.default.APP.appLink[appName]) {
      return `${_environment.default.APP.appLink[appName]}/${path}`;
    }
    const [app, ...domainTokens] = window.location.hostname.split('.');
    if (app === 'commander') {
      // old style url https://commander.demo.vinka.cloud
      const domain = domainTokens.join('.');
      return `https://${appName}.${domain}/${path}`;
    } else {
      // e.g https://demo.vinka.cloud/booker/
      const domain = [app, ...domainTokens].join('.');
      return `https://${domain}/${appName}/${path}`;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(appLink);
});