define("commander/pods/components/copy-shift-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    calendar: Ember.inject.service('power-calendar'),
    classNames: ['copy-shift-popup'],
    collection: Ember.A([]),
    didInsertElement() {
      this.calendar.set('locale', this.i18n.locale);
    },
    actions: {
      copy(dropdown) {
        dropdown.actions.close();
        this.sendAction('copy', this.collection);
      },
      changeYear(calendar, e) {
        const newCenter = new Date(Date.parse(calendar.center));
        newCenter.setYear(e.target.value);
        calendar.actions.changeCenter(newCenter);
      }
    }
  });
});