define("commander/helpers/timediff-min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timediffMin = timediffMin;
  function timediffMin(params /*, hash*/) {
    const diffms = parseInt(params[0]);
    let result = '';
    if (!isNaN(diffms)) {
      const minutes = Math.round(diffms / 60000);
      if (minutes <= -1) {
        result = '' + minutes;
      } else if (minutes >= 1) {
        result = '+' + minutes;
      }
    }
    return result.length > 0 ? `(${result})` : result;
  }
  var _default = _exports.default = Ember.Helper.helper(timediffMin);
});