define("commander/pods/index/route", ["exports", "commander/config/environment", "commander/utils/ui-constants"], function (_exports, _environment, _uiConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MODEL_REFRESH_INTERVAL = 300000;
  const qs = {
    tripToQP: i => `T${i.id}`,
    vehicleToQP: i => `V${i.id}`,
    messageToQP: i => `M${i.id}`,
    routeToQP: i => `R${i.id}`,
    entityToQP: i => `${i.typeName[0].toUpperCase()}${i.id}`,
    idFromQP: i => i.substring(1)
  };
  const qpListeners = {
    // subscribe to receive 'timeFrame' changes from qpService
    timeFrame: 'setTimeFrame',
    // subscribe to receive 'followVehicles' changes from qpService
    followVehicles: 'setFollowVehicles',
    trip: 'showTrip',
    vehicle: 'showVehicle',
    pinned: 'setPinned'
  };
  var _default = _exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    dataFilterService: Ember.inject.service('data-filter'),
    vehicleLocationService: Ember.inject.service('vehicle-locations'),
    qpService: Ember.inject.service('qpService'),
    messenger: Ember.inject.service(),
    i18n: Ember.inject.service(),
    removeTripObserver: () => {},
    removeRouteObserver: () => {},
    reloadTimer: null,
    queryParams: {
      details: {
        refreshModel: false
      },
      pinned: {
        refreshModel: false
      },
      timeFrame: {
        refreshModel: false
      },
      followVehicles: {
        refreshModel: false
      }
    },
    beforeModel() {
      if (!this.auth.isAuthenticated) {
        this.transitionTo('login');
      }
    },
    init() {
      this._super(...arguments);
      this._model.set('latestLocationsData', this.vehicleLocationService.get('latestLocations'));
      if (_environment.default.APP.leaflet.browserLocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          this._model.set('map.initLat', pos.coords.latitude);
          this._model.set('map.initLng', pos.coords.longitude);
        });
      }
      for (const [k, v] of Object.entries(qpListeners)) {
        this.qpService.on(k, this, v);
      }
    },
    willDestroyElement() {
      for (const [k, v] of Object.entries(qpListeners)) {
        this.qpService.off(k, this, v);
      }
    },
    activate() {
      this._super(...arguments);
      this._setReloadTimer();
      this.set('removeTripObserver', this.dataFilterService.addTripObserver(this.refresh.bind(this)));
      this.set('removeRouteObserver', this.dataFilterService.addRouteObserver(this.refresh.bind(this)));
    },
    _setReloadTimer() {
      this.reloadTimer = Ember.run.later(() => {
        console.log('reloading model');
        this.dataFilterService.reloadAll();
        this._setReloadTimer();
      }, MODEL_REFRESH_INTERVAL);
    },
    deactivate() {
      this._super(...arguments);
      Ember.run.cancel(this.reloadTimer);
      this.removeTripObserver();
      this.removeRouteObserver();
    },
    _model: Ember.Object.create({
      map: Ember.Object.create({
        initLat: _environment.default.APP.leaflet.mapOptions.center[0],
        initLng: _environment.default.APP.leaflet.mapOptions.center[1],
        zoom: _environment.default.APP.leaflet.mapOptions.zoom,
        zoomControl: _environment.default.APP.leaflet.mapOptions.zoomControl,
        disableAutoZoom: _environment.default.APP.leaflet.mapOptions.disableAutoZoom,
        tomtom: _environment.default.APP.leaflet.tomtom,
        tileLayer: Ember.Object.create({
          url: _environment.default.APP.leaflet.tileLayer.url,
          key: _environment.default.APP.leaflet.mapOptions.key
        })
      }),
      route: Ember.Object.create({
        routes: Ember.A([]),
        hover: null
      }),
      trip: Ember.Object.create({
        trips: Ember.A([]),
        hover: null
      }),
      stop: Ember.Object.create({
        hover: null
      }),
      vehicle: Ember.Object.create({
        vehicles: Ember.A([])
      }),
      message: Ember.Object.create({
        messages: Ember.A([]),
        hover: null
      }),
      details: Ember.A([]),
      filtered: Ember.A([]),
      latestLocationsData: Ember.A([]),
      customerStyle: _environment.default.APP.customerStyle,
      messengerEnabled: false,
      allAttributes: Ember.A([])
    }),
    model(queryParams) {
      const routearr = this.store.peekAll('route');
      const triparr = this.store.peekAll('trip');
      const vehiclearr = this.store.peekAll('vehicle');
      const messagearr = this.store.peekAll('message');
      const allAttributes = this.store.peekAll('attribute');
      const allCapacities = this.store.peekAll('capacity');
      this.set('_model.route.routes', routearr);
      this.set('_model.trip.trips', triparr);
      this.set('_model.vehicle.vehicles', vehiclearr);
      this.set('_model.message.messages', messagearr);
      this.set('_model.messengerEnabled', this.messenger.get('enabled'));
      this.set('_model.allAttributes', this.dataFilterService.createAttributeList(allAttributes));
      this.set('_model.allCapacities', this.dataFilterService.createCapacityList(allCapacities));
      if (this.controller) {
        this.openDetailsFromQP(queryParams.details);
      }
      return Ember.RSVP.hash(this._model);
    },
    setEntityHover(entity, show) {
      let typeName = entity.typeName;
      if (typeName === 'candidates') {
        typeName = 'route';
        entity = entity.route;
      }
      this._model[typeName].set('hover', show ? entity : null);
    },
    actions: {
      tripClicked(trip) {
        this.controller.addQP('details', qs.tripToQP(trip));
        // only one trip dialog is allowed at the moment - before adding a new one - remove the previous
        this.addDetailView(trip, true, true);
      },
      routeClicked(route) {
        this.controller.addQP('details', qs.routeToQP(route));
        this.addDetailView(route, true, true);
      },
      vehicleClicked(vehicleNumber) {
        if (vehicleNumber) {
          let vehicleEntity = this.store.peekRecord('vehicle', vehicleNumber);
          if (vehicleEntity) {
            this.controller.addQP('details', qs.vehicleToQP(vehicleEntity));
            this.addDetailView(vehicleEntity, true);
          }
        }
      },
      vehicleFiltered(vehicleNumbers) {
        this.get('_model.filtered').clear();
        vehicleNumbers.forEach(number => {
          this.get('_model.filtered').pushObject(number);
        });
      },
      showCandidatesDetail(route, candidates) {
        const entity = Ember.Object.create({
          id: route.get('id'),
          typeName: 'candidates',
          route: route,
          rounds: candidates
        });
        this.addDetailView(entity, true, true); // only one entity at a time
      },
      messageClicked(message) {
        this.controller.addQP('details', qs.messageToQP(message));
        this.addDetailView(message, true, true); // only one message open at a time
      },
      closeDetail(entity) {
        const details = this.get('_model.details');
        this.controller.removeQP('details', qs.entityToQP(entity));
        details.removeObject(entity);

        // remove hovered route/trip
        this.removeHover(entity);
        this.qpService.setQP('details', null);
      },
      onHover(entity, show) {
        this.setEntityHover(entity, show);
      },
      detailEvent(type, entity) {
        // custom events from detail views
        if (type === _uiConstants.DETAIL_VIEW_EVENTS.stopClick) {
          // check if the same trip is already opened. If yes - do nothing, otherwise open the trip.
          const currentTrip = this.findFromDetails(entity.get('typeName'));
          if (!currentTrip || currentTrip.get('id') !== entity.get('id')) {
            this.send('tripClicked', entity);
          }
        }
      },
      // needed, because when ember-table is hidden, it is unable to calculate the sizes of occlusion areas.
      // It should implement its own `resize` listening, but it somehow does not work.
      triggerOcclusion() {
        window.dispatchEvent(new Event('resize'));
      }
    },
    // entity - route/trip entity with typeName
    removeHover(entity) {
      let typeName = entity.get('typeName');
      if (typeName === 'candidates') {
        typeName = 'route';
        entity = entity.route;
      }
      const modelEntity = this._model.get(typeName); // typeName: 'route'/'trip' etc

      if (modelEntity && modelEntity.get('hover') && modelEntity.get('hover.id') === entity.get('id')) {
        modelEntity.set('hover', null);
      }
    },
    // singleMode - true if only one of 'typeName' should be kept open
    addDetailView(entity, toggle = false, singleMode = false) {
      const typeName = entity.get('typeName');
      const id = entity.get('id');
      const detailsEntity = this.findFromDetails(typeName, id);
      if (singleMode) {
        const earlierItem = this.findFromDetails(entity.get('typeName'));

        // trigger a default 'closeDetail' action for the previously opened trip
        if (earlierItem && earlierItem.get('id') !== entity.get('id')) {
          this.send('closeDetail', earlierItem);
        }
      }
      if (!detailsEntity) {
        const details = {
          routeId: typeName === 'route' ? entity.id : entity.routeId,
          tripId: typeName === 'route' ? entity.trips[0].id : entity.id
        };
        this.qpService.setQP('details', details);
        this.get('_model.details').pushObject(entity);
      } else if (toggle) {
        // if toggling and not found -> remove
        this.get('_model.details').removeObject(detailsEntity);
        this.qpService.setQP('details', null);
      }
    },
    findFromDetails(typeName, id) {
      return this.get('_model.details').find(i => i.get('typeName') === typeName && (!id || i.get('id') === `${id}`));
    },
    removeDetailsEntity(typeName, id) {
      this.get('_model.details').removeObject(this.findFromDetails(typeName, id));
    },
    openDetailsFromQP(detailsQP) {
      this.setupUIfromQP(detailsQP, 'details', ent => {
        this.addDetailView(ent);
      }, (id, type) => {
        this.removeDetailsEntity(type, id);
      });
    },
    setupUIfromQP(qpsz, qptype, cb, removeCb) {
      const pinArr = this.controller.qpToArr(qpsz);
      pinArr.forEach(item => {
        if (!item) {
          return;
        }
        const shorts = {
          T: 'trip',
          V: 'vehicle',
          M: 'message',
          R: 'route'
        };
        const id = qs.idFromQP(item);
        const reponame = shorts[item[0]];
        if (id && reponame) {
          const ent = this.store.peekRecord(reponame, id);
          if (ent) {
            cb(ent);
          } else {
            this.controller.removeQP(qptype, item);
            if (removeCb) {
              removeCb(id, reponame);
            }
          }
        } else {
          this.controller.removeQP(qptype, item);
        }
      });
    },
    setTimeFrame(val) {
      this.controller.setQP('timeFrame', [val]);
    },
    setFollowVehicles(val) {
      this.controller.setQP('followVehicles', [val]);
    },
    setPinned(val) {
      this.controller.setQP('pinned', (val || '').split(','));
    },
    showTrip(val) {
      const trip = this.store.peekRecord('trip', val);
      // check if the same trip is already opened. If yes - do nothing, otherwise open the trip.
      const currentTrip = this.findFromDetails('trip');
      if (trip && (!currentTrip || currentTrip.get('id') !== trip.get('id'))) {
        this.send('tripClicked', trip);
      }
    },
    showVehicle(val) {
      this.send('vehicleClicked', val);
    }
  });
});