define("commander/instance-initializers/i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // Locale initializer
  function initialize(appInstance) {
    const i18n = appInstance.lookup('service:i18n');
    const localeService = appInstance.lookup('service:locale');
    if (i18n && localeService) {
      const langChoice = localeService.getLocalStorageLocale();
      if (langChoice) {
        localeService.setI18NLocale(langChoice);
      }
    } else {
      console.error('No lang services found for setting language', !i18n, !localeService);
    }
  }
  var _default = _exports.default = {
    initialize
  };
});