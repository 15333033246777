define("commander/mixins/break-list", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    actions: {
      newBreak() {
        this.store.createRecord('break', {
          vehicleNumber: this.get('vehicle.number'),
          periodStart: new Date(),
          periodEnd: moment().add(1, 'hours').toDate(),
          duration: _environment.default.APP.breakDuration
        });
      },
      cancelNewBreak(item) {
        item.deleteRecord();
      },
      removeBreak(item) {
        item.destroyRecord();
      }
    },
    breakErrorPopup(operation, code) {
      this.notifications.error(this.i18n.t(`breakList.errors.${operation}.${code}`), {
        cssClasses: 'commander-notification',
        autoClear: true,
        clearDuration: 5000
      });
    }
  });
});