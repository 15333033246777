define("commander/pods/components/map-vehicle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0d/Ou2oQ",
    "block": "{\"symbols\":[\"@vehicle\",\"@onVehicleHover\",\"@onVehicleOut\",\"@vehicleClicked\"],\"statements\":[[8,\"marker-layer\",[],[[\"@location\",\"@icon\",\"@onMouseover\",\"@onMouseout\",\"@onClick\"],[[32,1,[\"gps\"]],[32,0,[\"vehicleMarker\"]],[30,[36,0],[[32,2],[32,1,[\"vehicleNumber\"]]],null],[32,3],[30,[36,0],[[32,4],[32,1,[\"vehicleNumber\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "commander/pods/components/map-vehicle/template.hbs"
    }
  });
});