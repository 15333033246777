define("commander/pods/components/break-table/component", ["exports", "commander/mixins/data-sorter"], function (_exports, _dataSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_dataSorter.default, {
    sortDataProperty: 'breaks',
    toBeSorted: 'breaks',
    sortedResult: 'sorted',
    initialSort: {
      by: 'periodStart',
      type: 'date',
      reversed: false
    },
    init() {
      this._super(...arguments);
      this.set('_lastSort', this.initialSort);
    },
    didInsertElement() {
      this.sortAgain();
    },
    dataChange: Ember.observer('breaks.[]', function () {
      this.sortAgain();
    }),
    actions: {
      cancelNew(br) {
        this.sendAction('cancelNew', br);
      },
      removeBreak(br) {
        this.sendAction('removeBreak', br);
      }
    }
  });
});