define("commander/pods/components/trip-delete-reason/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IQxBNzNn",
    "block": "{\"symbols\":[\"reason\"],\"statements\":[[10,\"div\"],[14,0,\"row align-items-end\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"tripList.deleteTripConfirm.reason\"],null]],[2,\":\\n        \"],[11,\"select\"],[24,0,\"form-control form-control-sm\"],[4,[38,3],[\"change\",[32,0,[\"onReasonSelect\"]]],null],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"reasonOptions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,0],[[32,1],[32,0,[\"tripDeleteReason\"]]],null]],[12],[1,[30,[36,2],[[30,[36,1],[\"trip.deleteReason.\",[32,1]],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row align-items-end\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"tripList.deleteTripConfirm.description\"],null]],[2,\":\\n        \"],[11,\"input\"],[24,0,\"form-control form-control-sm\"],[16,2,[32,0,[\"tripDeleteDescription\"]]],[4,[38,3],[\"change\",[32,0,[\"onDescriptionSelect\"]]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"concat\",\"t\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "commander/pods/components/trip-delete-reason/template.hbs"
    }
  });
});