define("commander/models/latest-vehicle-location", ["exports", "@ember-data/model", "commander/helpers/local-time-format"], function (_exports, _model, _localTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    vehicleNumber: (0, _model.attr)('string'),
    routeId: (0, _model.attr)('string'),
    vehicleTime: (0, _model.attr)('date'),
    serverTime: (0, _model.attr)('date'),
    gps: (0, _model.attr)(),
    vehicleTimeLocal: Ember.computed('gps', 'vehicleTime', function () {
      const time = this.gps;
      const timeMoment = time && time.timestamp ? moment(time.timestamp) : undefined;
      return timeMoment ? (0, _localTimeFormat.localTimeDateString)(timeMoment) : '';
    })
  });
});