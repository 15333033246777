define("commander/utils/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STOP_STATES = {
    planned: 'planned',
    arrived: 'arrived',
    performed: 'performed',
    noShow: 'no-show'
  };
  const DROP_TYPE = 'drop';
  const PICK_TYPE = 'pick';
  const TRIP_LATE_THRESHOLD = 5 * 60;
  const TRIP_STATES = {
    noShow: 4,
    onBoard: 2,
    finished: 3,
    arrivingPickup: 1,
    notStarted: 0
  };

  // we should display these dispatch statuses right next to the trip statuses when sorting. So the values should be between 0 and 1
  const DISPATCH_STATUSES = {
    notstarted: {
      id: 'notstarted',
      sort: 0.01,
      icon: ''
    },
    started: {
      id: 'started',
      sort: 0.1,
      icon: 'sync'
    },
    paused: {
      id: 'paused',
      sort: 0.2,
      icon: 'pause-circle'
    },
    failed: {
      id: 'failed',
      sort: 0.3,
      icon: 'exclamation-triangle'
    },
    offering: {
      id: 'offering',
      sort: 0.4,
      icon: 'sync'
    },
    accepted: {
      id: 'accepted',
      sort: 0.5,
      icon: 'dot-circle'
    },
    rejected: {
      id: 'rejected',
      sort: 0.6,
      icon: 'exclamation-triangle'
    }
  };
  const ROUTE_STATUSES = {
    ...DISPATCH_STATUSES,
    inProgress: {
      id: 'inProgress',
      sort: 0.7,
      icon: 'taxi'
    },
    completed: {
      id: 'completed',
      sort: 0.8,
      icon: 'check-circle'
    },
    manual: {
      id: 'manual',
      sort: 0.8,
      icon: 'hand'
    }
  };
  const VEHICLE_ATTRIBUTES = {
    1600: 'test-vehicle',
    1619: 'bus',
    1601: 'station-wagon',
    1618: 'sedan',
    1614: 'paratransit',
    1612: 'lowrider',
    1504: 'pet-friendly',
    1621: 'non-smoking',
    1613: 'stretcher',
    1616: 'stairclimber',
    1628: 'front-seat',
    1615: 'wheelchair-lift',
    1690: 'alcometer',
    1627: 'electric-wheelchair',
    1632: 'multiple-wheelchairs'
  };

  // TODO: check if there is a better way to determine the "visited" state of the stop...
  function visited(stop) {
    return stop.completion && stop.completion.status !== STOP_STATES.planned;
  }

  // TODO: this function asks for refactoring.
  function findNextUnperformed(stops) {
    let i;
    let next = stops.get('lastObject');
    for (i = stops.get('length') - 1; i >= 0; i -= 1) {
      const stop = stops.objectAt(i);
      if (!visited(stop)) {
        next = stop;
      } else {
        break;
      }
    }
    return next;
  }

  // returns how many seconds late the route is (negative number means ahead of time)
  function secondsLate(stops) {
    const stop = findNextUnperformed(stops);
    return calculateStopDelay(stop) / 1000;
  }
  function calculateStopDelay(stop) {
    if (stop) {
      let planned = stop.plannedTime || stop.promisedTime || stop.requestedTime;
      let actual = stop.completion && stop.completion.status === STOP_STATES.performed ? stop.completion.timestamp : stop.eta;
      if (planned && actual) {
        const plannedDate = new Date(planned);
        const actualDate = new Date(actual);
        return actualDate.getTime() - plannedDate.getTime();
      }
    }
    return 0;
  }
  function setTripPath(r, t) {
    Ember.set(t, 'path', r.getTripPath(t));
  }
  function formattedAddress(location, full = true) {
    // @todo should be locale specific

    let keys = ['alias', 'street', 'streetNumber', 'door', ...(full ? ['city', 'zipcode', 'country', 'province'] : [])];
    const fragments = [];
    for (let key of keys) {
      if (location[key]) {
        fragments.push(location[key]);
      }
    }
    return fragments.join(', ');
  }
  function getStop(itineraryEvent) {
    return itineraryEvent.subType === DROP_TYPE ? itineraryEvent.item.get('stop.drop') : itineraryEvent.item.get('stop.pick');
  }
  function createPerform(itineraryEvent, status) {
    const stop = itineraryEvent.item;
    const type = itineraryEvent.subType;
    const tripId = itineraryEvent.trip ? itineraryEvent.trip.get('id') : undefined;
    const tripUuid = itineraryEvent.trip ? itineraryEvent.trip.get('uuid') : undefined;
    const routeId = itineraryEvent.route.get('id');
    const vehicleNumber = itineraryEvent.route.get('vehicleNumber');
    return {
      stopId: stop.id,
      vehicleNumber,
      status,
      itemType: `stop-${type}`,
      vehicleTime: stop.plannedTime,
      serverTime: stop.plannedTime,
      gps: {
        lat: stop.lat,
        lng: stop.lng,
        timestamp: stop.plannedTime,
        fix: 60,
        speed: 0,
        bearing: 90
      },
      routeId,
      tripId,
      tripUuid
    };
  }
  function completionToTripStatus(completionStatus) {
    switch (completionStatus) {
      case STOP_STATES.arrived:
        return TRIP_STATES.arrivingPickup;
      case STOP_STATES.noShow:
        return TRIP_STATES.noShow;
      case STOP_STATES.planned:
        return TRIP_STATES.notStarted;
      case STOP_STATES.performed:
        return TRIP_STATES.finished;
      default:
        return TRIP_STATES.notStarted;
    }
  }
  var _default = _exports.default = {
    secondsLate,
    setTripPath,
    calculateStopDelay,
    formattedAddress,
    getStop,
    createPerform,
    completionToTripStatus,
    TRIP_LATE_THRESHOLD,
    TRIP_STATES,
    STOP_STATES,
    DISPATCH_STATUSES,
    ROUTE_STATUSES,
    VEHICLE_ATTRIBUTES
  };
});