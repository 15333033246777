define("commander/services/display-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // We use these default values until someone initializes this service.
  // In practice these wouldn't be used after Commander has started.
  const WINDOW_START = -20;
  const WINDOW_END = 30;
  var _default = _exports.default = Ember.Service.extend({
    fixedWindow: null,
    // object {start: Date, end: Date}
    realtimeWindow: null,
    // object {start: ms, end: ms}
    rangeCallbacks: {},
    displayLabel: null,
    init() {
      this._super(...arguments);
      this.set('realtimeWindow', {
        WINDOW_START,
        WINDOW_END
      });
    },
    /**
     * @param {Date} start - start time for the interval.
     * @param {Date} end - end time for the interval
     * @param {String | null} label - display label associated with window
     */
    setFixedWindow(start, end, label = null) {
      this.set('fixedWindow', {
        start: start,
        end: end
      });
      this.set('realtimeWindow', null);
      this.set('displayLabel', label);
      this._callRangeObservers();
    },
    /**
     * @param {Number} start - relative start time in minutes (negative if in past)
     * @param {Number} end - relative end time in minutes
     * @param {String | null} label - display label associated with window
     */
    setRealtimeWindow(start, end, label = null) {
      this.set('fixedWindow', null);
      this.set('realtimeWindow', {
        start: start * 60000,
        end: end * 60000
      });
      this.set('displayLabel', label);
      this._callRangeObservers();
    },
    /**
     * @param {Date} now - current time, default is current date.
     */
    getWindow(now = new Date()) {
      if (this.fixedWindow) {
        return this.fixedWindow;
      } else {
        return {
          start: new Date(now.getTime() + this.realtimeWindow.start),
          end: new Date(now.getTime() + this.realtimeWindow.end)
        };
      }
    },
    addRangeObserver(id, callback) {
      this.rangeCallbacks[id] = callback;
    },
    removeRangeObserver(id) {
      delete this.rangeCallbacks[id];
    },
    _callRangeObservers() {
      const {
        start,
        end
      } = this.getWindow();
      const label = this.displayLabel;
      Object.values(this.rangeCallbacks).forEach(callback => {
        callback({
          start,
          end,
          label
        });
      });
    }
  });
});