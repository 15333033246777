define("commander/pods/components/detail-panel/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let DetailPanel = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, (_class = class DetailPanel extends _component.default {
    constructor() {
      super(...arguments);

      // this.element.scrollIntoView({
      //     behavior: 'smooth'
      // });
    }
    handleMouseEnter() {
      if (this.args.entity.type !== 'trip') {
        this.args.hover(this.args.entity, true);
      }
    }
    closeDetail() {
      this.args.closeDetail(this.args.entity);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleMouseEnter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDetail", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "closeDetail"), _class.prototype)), _class));
});