define("commander/pods/components/map-main/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  /* global L */
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let MapMain = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('qpService.qps.followVehicles'), _dec10 = Ember.computed('args.model.latestLocationsData.length', 'pinnedTrips.[]', 'args.model.details.[]', 'args.model.filtered.[]', 'isFollowingVehicles', 'mapService.{focusedVehicles,selectedVehicles}'), _dec11 = Ember.computed('args.model.route.hover'), _dec12 = Ember.computed.reads('mapService.hoveredStop'), _dec13 = Ember.computed.reads('mapService.hoveredTrip'), _dec14 = Ember.computed.reads('mapService.hoveredRoute'), _dec15 = Ember.computed.reads('mapService.selectedTrip'), _dec16 = Ember.computed('qpService.pinnedTrips', 'args.model.trip.trips.[]'), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class MapMain extends _component.default {
    get isFollowingVehicles() {
      return this.qpService.getQP('followVehicles');
    }

    /**
     * Returns vehicle locations with selection flag (if vehicle should be shown as selected)
     */
    get vehicleLocations() {
      // here is a newer implementation using an appropriate service 8)
      const focusedVehicles = this.mapService.focusedVehicles;
      if (focusedVehicles) {
        const selectedVehicles = this.mapService.selectedVehicles || [];
        return this.args.model.latestLocationsData.filter(i => focusedVehicles.includes(i.vehicleNumber)).map(i => Ember.Object.create({
          location: i,
          selected: selectedVehicles.includes(i.vehicleNumber)
        }));
      }
      const selectedVehicles = this._getSelectedVehicles();
      let locations = this.args.model.latestLocationsData.map(item => {
        return Ember.Object.create({
          location: item,
          selected: selectedVehicles.includes(item.vehicleNumber)
        });
      });

      // filter only if there are vehicles selected and the flag is on
      if (this.isFollowingVehicles) {
        return selectedVehicles.length ? locations.filter(i => i.selected) : [];
      } else {
        return locations;
      }
    }
    get hoverRoute() {
      return this.args.model.route.hover ? this.args.model.route.hover : null;
    }
    get bounds() {
      if (this.selectedTrip) {
        if (this.selectedTrip.route) {
          return L.latLngBounds(this.selectedTrip.route.path);
        } else {
          return L.latLngBounds([this.selectedTrip.start, this.selectedTrip.end]);
        }
      } else if (this.mapRef) {
        return this.mapRef.getBounds();
      } else {
        return null;
      }
    }
    get pinnedTrips() {
      return this.args.model.trip.trips.filter(i => this.qpService.pinnedTrips.includes(i.id));
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "qpService", _descriptor2, this);
      _initializerDefineProperty(this, "mapService", _descriptor3, this);
      _initializerDefineProperty(this, "lat", _descriptor4, this);
      _initializerDefineProperty(this, "lng", _descriptor5, this);
      _initializerDefineProperty(this, "zoom", _descriptor6, this);
      _initializerDefineProperty(this, "zoomControl", _descriptor7, this);
      _initializerDefineProperty(this, "mapRef", _descriptor8, this);
      _initializerDefineProperty(this, "hoveredStop", _descriptor9, this);
      _initializerDefineProperty(this, "hoveredTrip", _descriptor10, this);
      _initializerDefineProperty(this, "hoveredRoute", _descriptor11, this);
      _initializerDefineProperty(this, "selectedTrip", _descriptor12, this);
      this.lat = this.args.model.map.initLat;
      this.lng = this.args.model.map.initLng;
      this.zoom = this.args.model.map.zoom;
      this.zoomControl = this.args.model.map.zoomControl;
    }

    /**
     * Finds all selections (route, trip, vehicle) and returns vehicle numbers concerned.
     */
    _getSelectedVehicles() {
      const tripPinnedVehicles = this.pinnedTrips.map(i => `${i.vehicleNumber}`);

      // slightly kludge smell here, tries to select vehicle number while details obj may contain it in different way
      const detailsWithVehicles = [...this.args.model.details, ...this.args.model.filtered].filter(i => !!i.vehicleNumber || !!i.number).map(i => `${i.vehicleNumber || i.number}`);
      return tripPinnedVehicles.concat(detailsWithVehicles);
    }
    onVehicleHover(vehicleNumber) {
      const vehicle = this.store.peekRecord('vehicle', vehicleNumber);
      this.mapService.setHoveredRoute(vehicle ? vehicle.getCurrentRoute() : null);
    }
    onVehicleOut() {
      this.mapService.setHoveredRoute(null);
    }
    setMapRef(e) {
      this.mapRef = e.target;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "qpService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mapService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "lat", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lng", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "zoom", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "zoomControl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "mapRef", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isFollowingVehicles", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isFollowingVehicles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "vehicleLocations", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "vehicleLocations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hoverRoute", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "hoverRoute"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hoveredStop", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hoveredTrip", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "hoveredRoute", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "selectedTrip", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pinnedTrips", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "pinnedTrips"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVehicleHover", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onVehicleHover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVehicleOut", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onVehicleOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setMapRef", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "setMapRef"), _class.prototype)), _class));
});