define("commander/pods/components/itinerary-stop/component", ["exports", "@glimmer/component", "commander/utils/route", "commander/config/environment"], function (_exports, _component, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // import { computed } from '@ember/object';

  // Immutable enum of stop types.
  const StopTypes = Object.freeze({
    PICK: 'pick',
    DROP: 'drop',
    FLEX: 'flex'
  });
  let ItineraryStop = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ItineraryStop extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isShowingPerformConfirm", _descriptor, this);
      _initializerDefineProperty(this, "isShowingNoShowConfirm", _descriptor2, this);
      _initializerDefineProperty(this, "notifications", _descriptor3, this);
      _initializerDefineProperty(this, "i18n", _descriptor4, this);
    }
    // @computed('args.event.item.highlighted')
    get highlighted() {
      return this.args.event.trip ? this.args.event.trip.get('highlighted') : false;
    }

    // @computed('args.event.subType', 'args.event.item.{formattedDropAddress,formattedPickAddress}')
    get address() {
      return _route.default.formattedAddress(this.args.event.item.location);
    }

    // @computed('args.vehicle.capacityConfigs')
    get maxCapacities() {
      const totalCaps = {};
      const caps = (this.args.vehicle || {}).capacityConfigs;

      // TODO: the component can also be used with routes without vehicles. Make sure this value is correctly calculated for such a case.
      if (caps && caps.length > 0) {
        caps[0].forEach(cap => {
          totalCaps[cap.unitId] = cap.count;
        });
      }
      return totalCaps;
    }
    get canPerform() {
      if (!_environment.default.APP.tripModification.scheduling) {
        return false;
      }
      let vehicleNumber;
      if (this.args.event.trip) {
        vehicleNumber = this.args.event.trip.vehicleNumber;
      } else if (this.args.event.route) {
        vehicleNumber = this.args.event.route.vehicleNumber;
      }
      if (!vehicleNumber) {
        return false;
      }
      const stop = this.args.event.item;
      const trip = this.args.event.trip;
      const now = new Date();
      const plannedTime = new Date(stop.plannedTime);
      const isPast = now > plannedTime;
      const isPickAndDropNotPerformed = this.args.event.subType === StopTypes.PICK && trip ? trip.stop.drop.completion.status === 'planned' :
      // A bit misleading, but meant to allow perform if not pick (with other conditions satisfied)
      true;
      return isPast && isPickAndDropNotPerformed && stop.completion.status === 'planned';
    }
    get timediff() {
      return _route.default.calculateStopDelay(this.args.event.item);
    }
    get iconName() {
      if (this.args.event.subType === StopTypes.PICK) {
        return 'plus';
      } else if (this.args.event.subType === StopTypes.DROP) {
        return 'minus';
      } else if (this.args.event.subType === StopTypes.FLEX) {
        return 'table';
      } else {
        return 'question';
      }
    }
    showError(response, autoClear = true) {
      const errorMessages = (response.errors || []).length ? response.errors : [{
        detail: response.message
      }];
      (errorMessages || []).forEach(i => {
        let message = i.message;
        try {
          // No idea if `detail` property is deprecated.
          // Just using it because it is used in other similar
          // error notifications
          message = JSON.parse(i.detail).message;
        } catch (e) {
          message = i.detail || message;
        }
        this.notifications.error(this.i18n.t(`stopPerform.errors.${message}`), {
          cssClasses: 'commander-notification',
          autoClear,
          clearDuration: 5000
        });
      });
    }
    aboutToPerform() {
      this.isShowingPerformConfirm = true;
    }
    performStop() {
      const perform = _route.default.createPerform(this.args.event, _route.default.STOP_STATES.performed);
      this.args.event.route.stopPerform(perform, 'performed').then(response => console.log(response)).catch(response => this.showError(response));
      this.isShowingPerformConfirm = false;
    }
    hidePerformConfirm() {
      this.isShowingPerformConfirm = false;
    }
    aboutToNoShow() {
      this.isShowingNoShowConfirm = true;
    }
    noShow() {
      const perform = _route.default.createPerform(this.args.event, _route.default.STOP_STATES.noShow);
      this.args.event.route.stopPerform(perform, 'performed').then(response => console.log(response)).catch(response => this.showError(response));
      this.isShowingNoShowConfirm = false;
    }
    hideNoShowConfirm() {
      this.isShowingNoShowConfirm = false;
    }
    onStopClick() {
      if (this.args.event.trip) {
        this.args.onStopClick(this.args.event.trip);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isShowingPerformConfirm", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isShowingNoShowConfirm", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "aboutToPerform", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "aboutToPerform"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "performStop", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "performStop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hidePerformConfirm", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "hidePerformConfirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "aboutToNoShow", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "aboutToNoShow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noShow", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "noShow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideNoShowConfirm", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "hideNoShowConfirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onStopClick", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onStopClick"), _class.prototype)), _class));
});