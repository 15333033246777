define("commander/pods/components/trip-status-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ut9yUgRl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"icon\",\"title\",\"spin\"],[[32,0,[\"statusIcon\"]],[32,0,[\"statusText\"]],[30,[36,0],[[32,0,[\"statusIcon\"]],\"sync\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"fa-icon\",\"statusIcon\",\"if\"]}",
    "meta": {
      "moduleName": "commander/pods/components/trip-status-icon/template.hbs"
    }
  });
});