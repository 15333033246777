define("commander/pods/components/daterange-picker-inputs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    tagName: 'div',
    classNames: ['input-group'],
    locale: null,
    date: null,
    init() {
      this._super(...arguments);
      this.set('locale', moment.locale());
    },
    actions: {
      // type is 'date' or 'time'
      // output is event with parameters [date] and type what changed ('date'/'time')
      onChange(type, e) {
        let date = e[0];
        if (type === 'date' && this.date) {
          // date input may have wrong time
          const oldDate = new Date(this.date);
          date.setHours(oldDate.getHours());
          date.setMinutes(oldDate.getMinutes());
        } else if (type === 'time' && this.date) {
          // time input may have wrong date
          const oldDate = new Date(this.date);
          date.setFullYear(oldDate.getFullYear());
          date.setMonth(oldDate.getMonth());
          date.setDate(oldDate.getDate());
        }
        this.sendAction('onChange', [date], type);
      },
      onReady(selectedDates, dateStr, instance) {
        const classes = instance.config.class.split(' ');
        for (const css of classes) {
          instance.calendarContainer.parentNode.classList.add(css);
          if (/col-/.test(css)) {
            instance.input.classList.remove(css);
          }
        }
      }
    }
  });
});