define("commander/pods/components/copy-shift-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kYq+0ENH",
    "block": "{\"symbols\":[\"calendar\"],\"statements\":[[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"card-header\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"shiftList.copy.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"card-block\"],[12],[2,\"\\n\\n        \"],[10,\"label\"],[14,0,\"card-title\"],[12],[1,[30,[36,0],[\"shiftList.copy.chooseDates\"],null]],[13],[2,\"\\n\\n        \"],[8,\"power-calendar-multiple\",[[24,0,\"calendar-small\"]],[[\"@center\",\"@selected\",\"@onCenterChange\",\"@onSelect\"],[[34,1],[34,2],[30,[36,4],[[32,0],[30,[36,3],[[35,1]],null]],[[\"value\"],[\"date\"]]],[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],[[\"value\"],[\"moment\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"Nav\"]],[[24,0,\"ember-power-calendar-nav\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,5],[[32,1,[\"center\"]],\"MMMM\"],null]],[2,\"\\n                \"],[10,\"input\"],[14,\"min\",\"1970\"],[14,\"max\",\"9999\"],[15,2,[30,[36,5],[[32,1,[\"center\"]],\"YYYY\"],null]],[15,\"oninput\",[30,[36,4],[[32,0],\"changeYear\",[32,1]],null]],[14,4,\"number\"],[12],[13],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,[32,1,[\"Days\"]],[],[[],[]],null],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n            \"],[11,\"label\"],[24,0,\"btn btn-primary\"],[4,[38,4],[[32,0],\"copy\",[35,6]],null],[12],[1,[30,[36,0],[\"shiftList.copy.submit\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"month\",\"collection\",\"mut\",\"action\",\"moment-format\",\"dropdown\"]}",
    "meta": {
      "moduleName": "commander/pods/components/copy-shift-popup/template.hbs"
    }
  });
});