define("commander/services/locale", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    change: false,
    // just for observers to observe locale change

    init() {
      this._super(...arguments);
      this.setMomentLocale(this.getI18NLocale());
      window.onlanguagechange = Ember.run.bind(this, () => {
        this.onLocaleChange();
      });
    },
    getI18NLocale() {
      const i18n = this.i18n;
      return i18n.get('locale');
    },
    getI18NShortLocale() {
      let lang = this.getI18NLocale();
      return lang ? lang.substring(0, 3) : '';
    },
    setMomentLocale(i18nLocale) {
      // Todo: perhaps we need to prefer commander UI setting ('ENV.i18n.defaultLocale') over browser locale?
      // Or then set the UI lang based on browser locale as well. Now those are a bit unsync (timestamps in US, lang Fin)?

      // Use system/browser locale for moment.js if available
      let locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
      locale = locale || navigator.userLanguage || i18nLocale;
      locale = _environment.default.moment.includeLocales.includes(locale) ? locale : i18nLocale;
      moment.locale(locale);
    },
    onLocaleChange() {
      const i18nLocale = this.getI18NLocale();
      this.setMomentLocale(i18nLocale);
      this.set('change', !this.change);
    },
    localeChange: Ember.observer('i18n.locale', function () {
      this.onLocaleChange();
    }),
    // Set UI language locale (does not affect date representation, which is taken from browser lang order)
    // Also store selection into local storage.
    setI18NLocale(i18NLocale) {
      const current = this.getI18NLocale();
      if (current !== i18NLocale) {
        this.i18n.set('locale', i18NLocale);
        localStorage.setItem('selectedLanguage', i18NLocale);
      }
    },
    getLocalStorageLocale() {
      return localStorage.getItem('selectedLanguage');
    }
  });
});