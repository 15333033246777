define("commander/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    beforeModel() {
      return Ember.RSVP.all([this.auth.checkLogin()]);
    }
  });
});