define("commander/services/auth", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    isAuthenticated: false,
    accessToken: undefined,
    keycloak: undefined,
    /**
     * Configure our auth0 instance
     */
    auth0: Ember.computed(function () {
      return new auth0.WebAuth({
        // setting up the config file will be covered below
        domain: _environment.default.auth0.domain,
        // domain from auth0
        clientID: _environment.default.auth0.clientId,
        // clientId from auth0
        redirectUri: `${window.location.origin}${_environment.default.rootURL}`,
        audience: _environment.default.auth0.audience,
        responseType: 'token id_token',
        // connection: ['Username-Password-Authentication', 'google-oauth2'],
        scope: 'openid profile email user_metadata' // adding profile because we want username, given_name, etc
      });
    }),
    /**
     * Configure our keycloak instance
     */
    keycloakInstance: Ember.computed(function () {
      if (!this.keycloak) {
        this.keycloak = new Keycloak({
          url: _environment.default.APP.keycloak.url,
          realm: _environment.default.APP.keycloak.realm,
          clientId: _environment.default.APP.keycloak.clientId || 'commander',
          flow: 'standard'
        });
      }
      return this.keycloak;
    }),
    /**
     * Send a user over to the hosted auth0 login page
     */
    login() {
      if (_environment.default.APP.keycloak) {
        this.keycloak.login();
      } else {
        this.get('auth0').authorize();
      }
    },
    /**
     * When a user lands back on our application
     * Parse the hash and store user info
     */
    handleAuthentication() {
      if (_environment.default.APP.keycloak) {
        return new Promise((resolve, reject) => {
          if (!this.isAuthenticated) {
            reject();
          }
          this.setUser(this.keycloak.token);
          resolve();
        });
      }
      return new Promise(resolve => {
        this.get('auth0').parseHash((err, authResult) => {
          if (err) {
            return false;
          }
          if (authResult && authResult.accessToken) {
            this.setUser(authResult.accessToken);
          }
          return resolve();
        });
      });
    },
    /**
     * Use the token to set our user
     */
    setUser(token) {
      this.accessToken = token;
      this.isAuthenticated = true;
      if (_environment.default.APP.keycloak) {
        const profile = JSON.parse(window.atob(token.split('.')[1]));
        this.set('user', profile);
      } else {
        // once we have a token, we are able to go get the users information
        this.get('auth0').client.userInfo(token, (err, profile) => this.set('user', profile));
      }
    },
    /**
     * Check if we are authenticated using the auth0 library's checkSession
     */
    checkLogin() {
      if (_environment.default.APP.keycloak) {
        return new Promise(resolve => {
          const keycloak = this.keycloakInstance;
          // in case login url then redirect to commander root url. Otherwise remember
          // the url with query strings etc. hence undefined
          const redirectUri = window.location.pathname.includes('login') ? `${window.location.origin}${_environment.default.rootURL}` : undefined;
          keycloak.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: `${window.location.origin}${_environment.default.rootURL}silent-check-sso.html`,
            redirectUri
          }).then(authenticated => {
            this.isAuthenticated = authenticated;
            if (authenticated) {
              this.setUser(keycloak.token);
            } else {
              return this.keycloak.login();
            }
            resolve(authenticated);
          }).then(authenticated => {
            this.isAuthenticated = keycloak.authenticated;
            if (this.isAuthenticated) {
              this.setUser(keycloak.token);
            }
            resolve(authenticated);
          }).catch(e => {
            resolve(false);
          });
        });
      }
      return new Promise(resolve => {
        this.get('auth0').checkSession({}, (err, authResult) => {
          if (err) {
            resolve(false);
          } else {
            this.setUser(authResult.accessToken);
            resolve(true);
          }
        });
      });
    },
    /**
     * Get rid of everything in sessionStorage that identifies this user
     */
    logout() {
      if (_environment.default.APP.keycloak) {
        this.keycloak.logout({
          redirectUri: `${window.location.origin}${_environment.default.rootURL}/login`
        });
      } else {
        this.get('auth0').logout({
          clientId: _environment.default.auth0.clientId,
          returnTo: `${window.location.origin}${_environment.default.rootURL}`
        });
      }
    }
  });
});