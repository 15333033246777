define("commander/pods/components/settings-event-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'tr',
    actions: {
      setHighlight() {
        this.toggleProperty('event.highlight');
        this.updateSettings(this.eventId, this.get('event.highlight'));
      },
      setNotify() {
        this.toggleProperty('event.notify');
        this.updateSettings(this.eventId, this.get('event.notify'));
      }
    }
  });
});