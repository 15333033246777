define("commander/helpers/search-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.searchWIndow = searchWIndow;
  /**
   * Converts a date string or date object into time string hhmm.
   * @param {Array} params First item in array is the one which is converted.
   */
  function searchWIndow(params) {
    const trip = params[0];
    function addSeconds(date, timeInSeconds) {
      return moment(date).add(timeInSeconds, 'seconds').format('LT');
    }
    let pickStart = '';
    let pickEnd = '';
    const pick = trip.ride ? trip.ride.pick : undefined;
    let pickTime = '';
    if (pick) {
      const searchWindowPick = trip.searchWindow ? trip.searchWindow.pick : undefined;
      const plannedTime = pick.plannedTime;
      if (searchWindowPick) {
        pickStart = addSeconds(plannedTime, -searchWindowPick.start);
        pickEnd = addSeconds(plannedTime, +searchWindowPick.end);
      }
      pickTime = pick.plannedTime ? moment(pick.plannedTime).format('LT') : '';
    }
    return pickTime ? `${pickTime} (${pickStart} - ${pickEnd})` : '';
  }
  var _default = _exports.default = Ember.Helper.helper(searchWIndow);
});