define("commander/services/vehicle-locations", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const locationMaxAgeMinutes = _environment.default.APP.vehicleLocations.filterByPingExpiration || 0;
  const filterByShift = !!_environment.default.APP.vehicleLocations.filterByShift;

  // currently poll interval until vehicles have been fetched
  const POLL_INTERVAL = 10000;
  const INIT_POLL_INTERVAL = 3000;
  // const POLL_INTERVAL = ENV.APP.vehicleLocations.interval * 1000; // disabled for now

  function pushLocationsToStore(locs, store) {
    // using pushPayload to deserialize and update existing and push new at the same time
    if (locs && locs.length > 0) {
      store.pushPayload({
        data: locs.map(t => {
          return {
            type: 'latest-vehicle-location',
            id: t.id,
            attributes: t
          };
        })
      });
    }
  }
  let VehicleLocationsService = _exports.default = (_dec = Ember.inject.service, (_class = class VehicleLocationsService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _defineProperty(this, "_started", false);
      _defineProperty(this, "_timer", null);
      _defineProperty(this, "latestLocations", Ember.A([]));
      this.start();
    }
    getOnShiftVehicles() {
      return this.store.peekAll('shift').filter(i => i.get('isDriving')).map(i => i.get('vehicleNumber'));
    }

    // Return vehicle numbers for vehicles that are considered to be driving based on env settings
    // - all by default
    // - filtering by ongoing shifts
    // - filtering by vehicle activity flag
    getCurrentVehicles() {
      return filterByShift ? this.getOnShiftVehicles() : this.store.peekAll('vehicle').map(i => i.get('number'));
    }

    // Return locations filtered by currently driving vehicles and location age
    getCurrentLocations() {
      const vehiclesDrivingNow = this.getCurrentVehicles();
      let locations = this.store.peekAll('latest-vehicle-location').filter(l => vehiclesDrivingNow.some(v => v === l.get('vehicleNumber')));

      // filtering by location age
      if (locationMaxAgeMinutes > 0) {
        const earliestMoment = moment().subtract(locationMaxAgeMinutes, 'minutes');
        locations = locations.filter(i => earliestMoment.diff(moment(i.get('gps.timestamp'))) < 0);
      }
      return locations;
    }
    start() {
      if (!this._started) {
        console.debug('fetching vehicle locations');
        this._started = true;
        Ember.run.later(() => this._doPoll(), INIT_POLL_INTERVAL);
      }
    }
    stop() {
      console.debug('stopping vehicle location fetch interval');
      if (this._timer) {
        Ember.run.cancel(this._timer);
      }
      this._started = false;
    }
    _doPoll() {
      this.store.findAll('latest-vehicle-location', {
        reload: true
      }).then(( /*result*/
      ) => {
        const allVehicles = this.store.peekAll('vehicle');
        if (allVehicles.get('length') > 0) {
          // stop polling as we have vehicles and can show vehicle locations
          this.stop();
        }
        this._renewLatestLocationData();
      }, err => {
        console.error('error in getting vehicle locations', err);
      }).finally(() => {
        if (this._started) {
          this._timer = Ember.run.later(this, this._doPoll, POLL_INTERVAL);
        } else {
          this._timer = null;
        }
      });
    }
    _renewLatestLocationData() {
      const locations = this.getCurrentLocations();
      this.latestLocations.clear();
      this.latestLocations.pushObjects(locations.map(i => i));

      // notify that array has changed, setting existing array won't work
      this.notifyPropertyChange('latestLocations');
    }
    prepareLocations(locations) {
      const locationsMap = locations.reduce((a, i) => {
        // remove duplicates.
        if (!a.has(i.vehicleNumber) || i.vehicleTime > a.get(i.vehicleNumber).vehicleTime) {
          // set id to match vehicle number
          i.id = i.vehicleNumber;
          return a.set(i.vehicleNumber, i);
        } else {
          return a;
        }
      }, new Map());
      return [...locationsMap.values()];
    }

    /**
     * Update from websocket
     * @param {*} locationUpdates array of raw ws data.
     */
    updateLocations(locationUpdates) {
      // filter out possible doubles for vehicles
      const locations = this.prepareLocations(locationUpdates);
      pushLocationsToStore(locations, this.store);
      this._renewLatestLocationData();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});