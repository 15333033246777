define("commander/services/qp-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Looks like all the 'routing' in this application is implemented using query params. So it would be nice to have all
  // the corresponding functions in one service. We make it 'Evented' so it could notify all the subscribers regarding
  // changes in certain parameters. The functionality of this service can be improved in future.
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    qps: {},
    pinnedTrips: Ember.computed('qps.pinned', function () {
      try {
        return this.qps.pinned.split(',').filter(i => i.startsWith('T')).map(i => i.substring(1));
      } catch (e) {
        return [];
      }
    }),
    detailView: Ember.computed('qps.details', function () {
      return this.get('qps.details');
    }),
    init() {
      this._super(...arguments);
      new URL(document.location).searchParams.forEach((value, key) => this.set(`qps.${key}`, value));
    },
    setQP(key, value) {
      this.set(`qps.${key}`, value);
      this.trigger(key, value);
    },
    toggleQP(key, value) {
      const current = this.getQP(key) || '';
      if (current.split(',').includes(value)) {
        this.setQP(key, current.split(',').filter(i => i !== value).join(','));
      } else {
        this.setQP(key, [current, value].filter(Boolean).join(','));
      }
    },
    getQP(name) {
      return this.get(`qps.${name}`) !== undefined ? this.get(`qps.${name}`) : new URL(document.location).searchParams.get(name);
    }
  });
});