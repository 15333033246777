define("commander/pods/components/map-route-points/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mhPQ0bqy",
    "block": "{\"symbols\":[\"stop\",\"@weight\",\"@route\"],\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"polyline-layer\",[],[[\"@weight\",\"@color\",\"@opacity\",\"@locations\"],[[32,2],\"black\",0.7,[32,3,[\"path\"]]]],null],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"stops\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@stroke\",\"@location\"],[7,[32,1,[\"color\"]],0.8,false,[32,1,[\"location\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "commander/pods/components/map-route-points/template.hbs"
    }
  });
});