define("commander/pods/components/daterange-picker-adv/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function setDayBegin(dt) {
    return moment(dt).startOf('day');
  }
  function setDayEnd(dt) {
    return moment(dt).endOf('day');
  }

  /**
   * copy and set time from original date object if it exists into given moment date.
   * returns same given date object
   * @param {Moment} dtMt
   * @param {date/moment/undefined/null} orig
   * @returns given dtMt object
   */
  function copyTime(dtMt, orig) {
    if (orig) {
      const existing = moment(orig);
      dtMt.hours(existing.hours()).minutes(existing.minutes());
    }
    return dtMt;
  }
  var _default = _exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    dataUpdate: Ember.inject.service('data-update'),
    dataFilter: Ember.inject.service('data-filter'),
    displayWindowService: Ember.inject.service('display-window'),
    qpService: Ember.inject.service('qpService'),
    ranges: {},
    // label -> function to get the range
    liveRanges: {},
    // label -> value of the relative display window in minutes [start, end]

    startDate: null,
    // set when fixed window is selected
    endDate: null,
    // set when fixed window is selected
    liveRange: null,
    // set when live window is selected
    searchLabel: 'datetime.ranges.search',
    // shortcut to i18n.t('datetime.ranges.search').string;
    currentLabel: null,
    previousLabel: null,
    _startDate: +moment(),
    _endDate: +moment(),
    _prevStartDate: null,
    // helper to remember time
    _prevEndDate: null,
    // helper to remember time
    pickerRange: Ember.computed('_startDate', '_endDate', function () {
      return [this._startDate, this._endDate];
    }),
    pickerShown: false,
    pickerLocale: null,
    customRangeLabel: '',
    // displayed string
    selectedRangeLabel: '',
    // displayed string
    liveText: '',
    // For displaying 'Live' text in UI

    init() {
      this._super(...arguments);
      let timeFrame = this.qpService.getQP('timeFrame');
      const [defaultLabel, defaultRange] = this.initRanges(timeFrame);
      this.updateSelection(defaultRange()[0], defaultRange()[1], defaultLabel);
      this.localeChangeObserver();

      // Add listener for when outside sources update windows, so that we can update labels etc. to match.
      this.displayWindowService.addRangeObserver('daterange-picker-adv-component', ({
        start,
        end,
        label
      }) => {
        if (label) {
          this.updateSelection(moment(start), moment(end), label, true);
        }
      });
    },
    actions: {
      onRangeSelect(dropdown, label) {
        const isSearch = this.isSearchRange(label);
        if (isSearch) {
          this.set('currentLabel', label);
          this.togglePicker(isSearch);
        } else {
          dropdown.actions.close();
          const range = this.ranges[label]();
          this.updateSelection(range[0], range[1], label);
        }
      },
      onPickerSelect(dropdown) {
        const pickerRange = this.pickerRange;
        pickerRange.sort((a, b) => +moment(a) - +moment(b)); // sort before sending

        if (pickerRange.length === 2) {
          dropdown.actions.close();
          const label = this.searchLabel;
          this.set('startDate', moment(pickerRange[0]));
          this.set('endDate', moment(pickerRange[1]));
          const range = this.ranges[label]();
          this.updateSelection(range[0], range[1], label);
        }
      },
      onPickerCancel(dropdown) {
        dropdown.actions.close();
        this.set('currentLabel', this.previousLabel);
        this.togglePicker(this.isSearchRange(this.currentLabel));
      },
      moveForward() {
        if (this.startDate) {
          const diff = this.calculateDuration();
          this.updateSelection(this.startDate.add(diff), this.endDate.add(diff), this.searchLabel);
        } else {
          const range = this.liveRange;
          this.updateSelection(moment().add(range[1], 'minutes'), moment().add(range[1] * 2, 'minutes'), this.searchLabel);
        }
      },
      moveBackward() {
        if (this.startDate) {
          const diff = this.calculateDuration();
          this.updateSelection(this.startDate.subtract(diff), this.endDate.subtract(diff), this.searchLabel);
        } else {
          const range = this.liveRange;
          this.updateSelection(moment().subtract(range[1] * 2, 'minutes'), moment().subtract(range[1], 'minutes'), this.searchLabel);
        }
      },
      /**
       * Dates selected from picker, try to preserve time information if earlier has been selected
       * @param {Array} dates one or two date items depending on whether end date selected
       */
      onPickerChange(dates) {
        const existingStart = this._prevStartDate;
        const existingEnd = this._prevEndDate;
        this.updatePickerStartDate(copyTime(moment(dates[0]), existingStart).toDate());
        const newEnd = dates[1] ? copyTime(setDayEnd(dates[1]), existingEnd).toDate() : null;
        this.updatePickerEndDate(newEnd);
      },
      // time change input
      onStartInputChange(dates) {
        this.updatePickerStartDate(dates[0]);
      },
      // time change input
      onEndInputChange(dates) {
        this.updatePickerEndDate(dates[0]);
      }
    },
    // update local picker start date managing properties.
    // we don't care if the dates are wrong way logically, order them when sending.
    updatePickerStartDate(date) {
      this.set('_startDate', date);
      if (date) {
        this.set('_prevStartDate', date);
      }
    },
    // update local picker end date managing properties
    // we don't care if the dates are wrong way logically, order them when sending.
    updatePickerEndDate(date) {
      this.set('_endDate', date);
      if (date) {
        this.set('_prevEndDate', date);
      }
    },
    togglePicker(toggle) {
      this.set('pickerShown', toggle);
      if (toggle) {
        this.updatePickerStartDate(+(this.startDate || setDayBegin()));
        this.updatePickerEndDate(+(this.endDate || setDayEnd()));
      }
    },
    initRanges(timeFrame) {
      const supportedHours = [1, 2, 3];
      const ranges = [['datetime.ranges.next30minutes', () => [moment().add(-20, 'minutes'), moment().add(30, 'minutes')]], ...supportedHours.map(hours => [`datetime.ranges.next${hours}hours`, () => [moment().add(-20, 'minutes'), moment().add(hours * 60, 'minutes')]]), ['datetime.ranges.today', () => [setDayBegin(), setDayEnd()]], ['datetime.ranges.yesterday', () => [setDayBegin().subtract(1, 'days'), setDayEnd().subtract(1, 'days')]], ['datetime.ranges.tomorrow', () => [setDayBegin().add(1, 'days'), setDayEnd().add(1, 'days')]], ['datetime.ranges.next2days', () => [setDayBegin(), setDayEnd().add(1, 'days')]], [this.searchLabel, () => [this.startDate || setDayBegin(), this.endDate || setDayEnd()]]];
      this.set('ranges', ranges.reduce((acc, c) => {
        acc[c[0]] = c[1];
        return acc;
      }, {}));
      const liveRanges = {
        'datetime.ranges.next30minutes': [-20, 30]
      };
      supportedHours.forEach(hours => {
        liveRanges[`datetime.ranges.next${hours}hours`] = [-20, hours * 60];
      });
      let defLabel = 'datetime.ranges.next30minutes';
      let defRange = this.ranges[defLabel];
      if (timeFrame && timeFrame.match(/^\d+-\d+$/)) {
        // for time frames defined through the timestamps
        defLabel = this.searchLabel;
        // all labels have corresponding ranges. However search option does not have one. Lets add it.
        defRange = () => [moment.unix(timeFrame.split('-').shift()), moment.unix(timeFrame.split('-').pop())];
      } else if (timeFrame && this.ranges[`datetime.ranges.${timeFrame}`]) {
        // for time frames defined by labels
        defLabel = `datetime.ranges.${timeFrame}`;
        defRange = this.ranges[defLabel];
      }
      this.set('liveRanges', liveRanges);
      this.set('selectedRangeLabel', defLabel);
      return [defLabel, defRange];
    },
    localeChangeObserver: Ember.observer('i18n.locale', function () {
      const fpLocales = flatpickr.l10ns;
      let locale = this.i18n.get('locale');
      if (fpLocales.hasOwnProperty(locale)) {
        locale = fpLocales[locale];
        locale.rangeSeparator = ' - ';
      }
      this.set('pickerLocale', locale);
      this.updateSelectionUI(this.previousLabel);
    }),
    calculateDuration() {
      if (this.startDate) {
        return this.endDate - this.startDate;
      } else {
        return (this.liveRange[1] - this.liveRange[0]) * 60000;
      }
    },
    isSearchRange(label) {
      return label === this.searchLabel;
    },
    isLiveRange(label) {
      return this.liveRanges.hasOwnProperty(label);
    },
    getLiveRange(label) {
      return this.liveRanges[label];
    },
    updateSelection(start, end, label, noDisplayWindowCall = false) {
      this.set('currentLabel', label);
      this.set('previousLabel', label);
      let val = label && !this.isSearchRange(label) ? label.split('.').pop() : `${start.format('X')}-${end.format('X')}`;
      this.qpService.setQP('timeFrame', val);
      this.updateSelectionData(start, end, label, noDisplayWindowCall);
      this.updateSelectionUI(label);
    },
    updateSelectionData(start, end, label, noDisplayWindowCall) {
      if (this.isLiveRange(label)) {
        [start, end] = this.getLiveRange(label);
        this.set('startDate', null);
        this.set('endDate', null);
        this.set('liveRange', [start, end]);
        if (!noDisplayWindowCall) {
          this.displayWindowService.setRealtimeWindow(start, end, label);
        }
      } else {
        this.set('startDate', start);
        this.set('endDate', end);
        this.set('liveRange', null);
        if (!noDisplayWindowCall) {
          this.displayWindowService.setFixedWindow(this.startDate.toDate(), this.endDate.toDate(), label);
        }
      }
    },
    updateSelectionUI(label) {
      const isSearch = this.isSearchRange(label);
      this.togglePicker(isSearch);
      this.set('selectedRangeLabel', label);
      this.set('liveText', this.isLiveRange(label) ? 'Live' : '');
      if (isSearch) {
        // user either clicked "search" or right/left arrows
        this.set('customRangeLabel', this.startDate.locale(this.i18n.get('locale')).format('MMMM D, YYYY HH:mm') + ' - ' + this.endDate.locale(this.i18n.get('locale')).format('MMMM D, YYYY HH:mm'));
      } else {
        // user clicked one of the shortcuts
        this.set('customRangeLabel', '');
      }
    }
  });
});