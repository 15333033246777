define("commander/pods/components/capacities-status/component", ["exports", "@glimmer/component", "commander/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember/object';

  const SHOW_CAPACITY_IDS = Object.keys(_environment.default.APP.capacities);
  const CAPACITY_LOAD_CLASSES = {
    full: 'full',
    almostfull: 'almostfull',
    free: 'free'
  };

  // only lists capacities defined in the ENV config
  class CapacitiesStatus extends _component.default {
    // @computed('args.capacities.[]')
    get orderedCapacities() {
      const caps = this.args.capacities;
      const orderedCaps = [];
      if (caps) {
        SHOW_CAPACITY_IDS.forEach(id => {
          const foundCount = caps[id];
          if (!isNaN(foundCount)) {
            let cap = Object.assign({
              count: foundCount || 0
            }, _environment.default.APP.capacities[id] || {
              icon: '',
              name: ''
            });
            this.setLoadStatus(id, cap);
            orderedCaps.push(cap);
          }
        });
      }
      return orderedCaps;
    }
    setLoadStatus(id, capacityStatus) {
      const maxCapacities = this.args.maxCapacities;
      if (maxCapacities && !isNaN(maxCapacities[id])) {
        const maxCount = maxCapacities[id];
        capacityStatus.status = CAPACITY_LOAD_CLASSES.free;
        if (maxCount <= capacityStatus.count) {
          capacityStatus.status = CAPACITY_LOAD_CLASSES.full;
        } else if (maxCount - 1 === capacityStatus.count) {
          capacityStatus.status = CAPACITY_LOAD_CLASSES.almostfull;
        }
      }
    }
  }
  _exports.default = CapacitiesStatus;
});