define("commander/pods/components/vehicle-number/component", ["exports", "@glimmer/component", "commander/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let VehicleNumber = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.route.seen'), _dec3 = Ember.computed('args.route.seen'), (_class = class VehicleNumber extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
    }
    // @computed('args.trip.routeId', 'args.trip.dispatchStrategyIncludedVehicles', 'args.route.dispatchStrategyIncludedVehicles')
    get includedVehicles() {
      let vehicles = [];
      if (this.args.route) {
        vehicles = this.args.route.dispatchStrategyIncludedVehicles;
      } else if (this.args.trip) {
        vehicles = this.args.trip.dispatchStrategyIncludedVehicles;
      }
      return [...new Set(vehicles)];
    }

    // note: to show seen, requires route to be given
    get isSeen() {
      // if the feature is disabled in configs - always return true,
      // otherwise return the actual value of the property
      return !_environment.default.APP.route.showDeliveredStatus || !this.args.showSeen || this.args.route?.seen === 'acked';
    }
    get seenText() {
      const seen = this.args.route ? this.args.route.seen : null;
      if (_environment.default.APP.route.showDeliveredStatus && seen) {
        return this.i18n.t(`routeDetails.seen.${seen}`, seen || '');
      }
      return '';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isSeen", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isSeen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "seenText", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "seenText"), _class.prototype)), _class));
});