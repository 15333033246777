define("commander/adapters/application", ["exports", "@ember-data/adapter/json-api", "commander/config/environment", "@ember-data/adapter/error"], function (_exports, _jsonApi, _environment, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ApplicationAdapter = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed('auth.accessToken'), (_class = class ApplicationAdapter extends _jsonApi.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "auth", _descriptor, this);
      _defineProperty(this, "host", `${_environment.default.APP.api.url}`);
    }
    get headers() {
      let headers = {};
      if (this.auth.isAuthenticated) {
        // OAuth 2
        headers['Authorization'] = `Bearer ${this.auth.accessToken}`;
      }
      return headers;
    }

    // response handler for converting api errors into form, that ember DS accepts
    // (otherwise error shows as string "[object object]")
    handleResponse(status, headers, payload, resp) {
      if (status >= 400 && status < 500) {
        const errors = payload.errors ? payload.errors : [payload];
        if (status === 401 && this.auth.isAuthenticated) {
          console.log('should redirect to login route');
          this.auth.logout();
        }
        return new _error.InvalidError(errors);
      }
      return super.handleResponse(...arguments);
    }

    /**
     * Override the original implementation, as it converts our server errors to `[object Object]` sting
     @method normalizeErrorResponse
     @private
     @param  {Number} status
     @param  {Object} headers
     @param  {Object} payload
     @return {Array} errors payload
     */
    normalizeErrorResponse(status, headers, payload) {
      // TODO this is a custom handler for our server side errors. If the structure changes, we'll need to apply the changes here as well
      if (payload && typeof payload === 'object' && payload.error && payload.message) {
        try {
          const error = JSON.parse(payload.message);
          return [{
            status: `${error.status}`,
            title: `${error.error}`,
            detail: `${error.message}`
          }];
        } catch (e) {
          return [{
            status: `${payload.status}`,
            title: `${payload.error}`,
            detail: `${payload.message}`
          }];
        }
      } else {
        return super.normalizeErrorResponse(status, headers, payload);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype)), _class));
});