define("commander/pods/components/language-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ksTs6lmA",
    "block": "{\"symbols\":[\"loc\"],\"statements\":[[10,\"div\"],[14,0,\"language-select\"],[12],[2,\"\\n    \"],[10,\"form\"],[12],[2,\"\\n        \"],[10,\"fieldset\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[1,[30,[36,2],[\"settings.language\"],null]],[13],[2,\"\\n            \"],[11,\"select\"],[24,0,\"form-control\"],[4,[38,3],[\"change\",[32,0,[\"setLocale\"]]],null],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"option\"],[15,2,[31,[[32,1,[\"id\"]]]]],[15,\"selected\",[30,[36,1],[[32,1,[\"id\"]],[35,0,[\"locale\"]]],null]],[12],[2,\"\\n                        \"],[1,[32,1,[\"text\"]]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"i18n\",\"is-equal\",\"t\",\"on\",\"locales\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "commander/pods/components/language-select/template.hbs"
    }
  });
});