define("commander/models/break", ["exports", "@ember-data/model", "commander/helpers/local-time-format"], function (_exports, _model, _localTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    duration: (0, _model.attr)('number'),
    vehicleNumber: (0, _model.attr)('string'),
    periodStart: (0, _model.attr)('date'),
    periodEnd: (0, _model.attr)('date'),
    plannedStart: (0, _model.attr)('date'),
    plannedEnd: (0, _model.attr)('date'),
    estimatedStart: (0, _model.attr)('date'),
    estimatedEnd: (0, _model.attr)('date'),
    // when we don't define the type, ember data won't send these (with null
    // values) at all in POST, which is useful considering ES's validation rules
    shiftId: (0, _model.attr)(),
    completion: (0, _model.attr)(),
    durationMinutes: Ember.computed('duration', function () {
      return this.duration / 60;
    }),
    startTimeLocal: Ember.computed('periodStart', function () {
      const time = this.periodStart;
      return (0, _localTimeFormat.localDateTimeString)(time);
    }),
    endTimeLocal: Ember.computed('periodEnd', function () {
      const time = this.periodEnd;
      return (0, _localTimeFormat.localDateTimeString)(time);
    }),
    isOnBreak: Ember.computed('periodStart', 'periodEnd', function () {
      const start = this.periodStart.getTime();
      const end = this.periodEnd.getTime();
      const now = new Date().getTime();
      return now < end && now > start;
    })
  });
});