define("commander/services/data-filter", ["exports", "commander/utils/route", "commander/config/environment"], function (_exports, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    locale: Ember.inject.service(),
    displayWindowService: Ember.inject.service('display-window'),
    dataUpdateService: Ember.inject.service('data-update'),
    _tripCallbacks: [],
    _routeCallbacks: [],
    _shiftCallbacks: [],
    // Only reload shifts on display window change
    reloadShiftsOnly: false,
    isFetchingData: false,
    init() {
      this._super(...arguments);
      this.displayWindowService.addRangeObserver('data-filter-service', this.onDisplayWindowChange.bind(this));
    },
    addTripObserver(callback) {
      this._tripCallbacks.push(callback);
    },
    removeTripObserver(callback) {
      const index = this._tripCallbacks.indexOf(callback);
      if (index > -1) {
        this._tripCallbacks.splice(index, 1);
      }
    },
    addRouteObserver(callback) {
      this._routeCallbacks.push(callback);
    },
    removeRouteObserver(callback) {
      const index = this._routeCallbacks.indexOf(callback);
      if (index > -1) {
        this._routeCallbacks.splice(index, 1);
      }
    },
    // TODO: Looks like these shift callbacks are never used...
    addShiftObserver(callback) {
      this._shiftCallbacks.push(callback);
      return this.removeShiftObserver.bind(this, callback);
    },
    removeShiftObserver(callback) {
      const index = this._shiftCallbacks.indexOf(callback);
      if (index > -1) {
        this._shiftCallbacks.splice(index, 1);
      }
    },
    _pushTripsToStore(trips) {
      if (trips && trips.length > 0) {
        this.store.push({
          data: trips.map(t => ({
            type: 'trip',
            id: t.id,
            attributes: t
          }))
        });
      }
    },
    _getIds(records) {
      return records.map(i => i.get ? i.get('id') : `${i.id}`);
    },
    _unloadOldRecords(oldRecords, newIds) {
      const store = this.store;

      // Do not unload `isNew` records
      oldRecords.filter(r => !newIds.includes(r.get('id')) && !r.get('isNew')).forEach(r => store.unloadRecord(r));
    },
    _reflect(promise) {
      return promise.then(value => ({
        value
      }), error => ({
        error
      }));
    },
    reloadAll() {
      this.onDisplayWindowChange(this.displayWindowService.getWindow());
    },
    onDisplayWindowChange(displayWindow) {
      if (this.reloadShiftsOnly) {
        this.reloadShifts(displayWindow);
        return;
      }
      const filter = {
        after: displayWindow.start.toISOString(),
        before: displayWindow.end.toISOString()
      };
      const store = this.store;
      const orgShifts = store.peekAll('shift');
      const orgRoutes = store.peekAll('route');
      const orgTrips = store.peekAll('trip');
      const orgBreaks = store.peekAll('break');
      const orgMessages = store.peekAll('message');
      this.set('isFetchingData', true);
      Ember.RSVP.all([store.query('route', filter),
      // TODO check if the filter below worksnow when we moved to STRING ids
      store.query('trip', Object.assign({
        route: 0
      }, filter)),
      // extend the filter by zero `route` param to receive trips without routes
      store.query('vehicle', {
        'omit-inactive': true
      }), store.query('shift', filter), store.query('break', filter), store.findAll('message'), store.findAll('attribute'), store.findAll('capacity'), store.findAll('contract'), store.findAll('label')].map(this._reflect)).then(results => {
        console.log('onDisplayWindowChange');
        const routes = results[0].value || [];
        const unroutedTrips = results[1].value || [];
        const vehicles = results[2].value || [];
        const shifts = results[3].value || [];
        const breaks = results[4].value || [];
        const messages = results[5].value || [];
        // results[6] is attributes, results[7] capacities, results[8] contracts, results[9] labels

        let newTrips = this._getIds(unroutedTrips);
        routes.forEach(r => {
          const trips = r.get('trips');
          trips.forEach(t => _route.default.setTripPath(r, t));
          newTrips = newTrips.concat(this._getIds(trips));
          this._pushTripsToStore(trips);
        });
        if (_environment.default.APP.dispatchEvents.listen) {
          const routesToFetchDispatchStatus = routes.filter(i => !i.get('vehicleNumber'));
          if (routesToFetchDispatchStatus.length) {
            routesToFetchDispatchStatus.forEach(i => i.setDispatchStatus('notstarted', null, true));

            // TODO I don't see any other way to call this `collectionAction` then directly from the instance of the class...
            routes.get('firstObject').getDispatchStatuses({
              ids: routesToFetchDispatchStatus.map(i => i.get('id')).join(',')
            }).then(response => response.forEach(i => {
              const route = store.peekRecord('route', i.routeId);
              if (route) {
                route.setDispatchStatus(i.status, i.vehicleNumber, true);
              }
            })).catch(reason => console.log(reason));
          }
        }
        vehicles.forEach(v => v.set('shiftOngoing', shifts.find(s => v.get('number') === s.get('vehicleNumber'))));
        this._unloadOldRecords(orgShifts, this._getIds(shifts));
        this._unloadOldRecords(orgBreaks, this._getIds(breaks));
        this._unloadOldRecords(orgRoutes, this._getIds(routes));
        this._unloadOldRecords(orgTrips, newTrips);
        this._unloadOldRecords(orgMessages, this._getIds(messages));
        this.updateAll();
        this.set('isFetchingData', false);

        // And if there's a read-only API -> fetch routes and trips
        this.dataUpdateService.fetchReadOnlyData(filter);
      });
    },
    reloadShifts(displayWindow) {
      const filter = {
        after: displayWindow.start.toISOString(),
        before: displayWindow.end.toISOString()
      };
      const store = this.store;
      const orgShifts = store.peekAll('shift');
      this.set('isFetchingData', true);
      store.query('shift', filter).then(shifts => {
        // shifts need vehicles -> load
        return store.findAll('vehicle').then(vehicles => {
          vehicles.forEach(v => v.set('shiftOngoing', shifts.find(s => v.get('number') === s.get('vehicleNumber'))));
          this._unloadOldRecords(orgShifts, this._getIds(shifts));
        });
      }).finally(() => {
        this.set('isFetchingData', false);
      });
    },
    updateAll() {
      this.updateShifts();
      this.updateTrips();
      this.updateRoutes();
    },
    // update messages by fetching all. This can be removed if data updating through is fully implemented for messages.
    updateMessages() {
      return this.store.findAll('message', {
        reload: true
      });
    },
    updateShifts(vehicleIds, onlyBreakUpdates) {
      this._shiftCallbacks.forEach(callback => callback(vehicleIds, onlyBreakUpdates));
    },
    updateRoutes(newRouteIds, removedRouteIds) {
      this._routeCallbacks.forEach(callback => callback(newRouteIds, removedRouteIds));
    },
    // TODO: check why the argument are not defined, however passed from 'data-updates'
    updateTrips() {
      this._tripCallbacks.forEach(callback => callback());
    },
    // --- helpers for sorting data ---
    /**
     * Return name for the attribute or capacity.
     * @param {*} attr Attribute or capacity object from API.
     * @param {*} defaultDefinition Attribute or capacity from env-definition, can be undefined (/should have {name: 'name'} structure).
     * @param {*} localizationPath Path to get from localizations 'commonAttributes'/'commonCapacities'.
     * @param {*} locName Property name for the translation e.g. value from object's id or name.
     * @param {*} defaultName Default name to be used if not found, can be undefined.
     * @returns Name as string.
     */
    attributeName(attr, defaultDefinition, localizationPath, locName, defaultName) {
      // first try attribute/capacity from store if it contains localizations
      let lang = this.locale.getI18NShortLocale() || 'en';
      let loc = (attr.i18n || {})[lang];

      // second try localization file
      if (!loc) {
        loc = this.i18n.exists(`${localizationPath}.${locName}`) ? this.i18n.t(`${localizationPath}.${locName}`) : null;
      }

      // third try default definitions from env
      if (!loc && defaultDefinition) {
        loc = defaultDefinition.name;
      }
      return loc || defaultName;
    },
    /**
     * @to-do is there better place for such helpers
     * Helper for creating UI friendly capacity list
     * If no capacities are available, return default definitions from env.
     * Capacity name is tried to be localized to the current chosen locale.
     * @returns  Capacity list. Format: [{id, name, icon, display},...]
     */
    createCapacityList(capacities) {
      const capDefinitions = _environment.default.APP.capacities;
      if (capacities) {
        const localizedList = capacities.map(cap => {
          const defaultCapDef = capDefinitions[+cap.id];
          return {
            id: +cap.id,
            name: this.attributeName(cap, defaultCapDef, 'commonCapacities', cap.name, cap.name),
            icon: cap?.display?.icon || (defaultCapDef || {}).icon || 'circle',
            display: cap.display
          };
        });
        return localizedList;
      }

      // no capacities from server either -> use env definitions
      return Object.keys(capDefinitions).map(key => {
        return {
          id: key,
          name: capDefinitions[key].name,
          icon: capDefinitions[key].icon
        };
      });
    },
    /**
     * @to-do is there better place for such helpers
     * Helper for creating UI friendly attribute list
     * Return attribute list fetched from store (server).
     * If no attributes are available, return default definitions from env.
     * Attribute name is tried to be localized to the current chosen locale.
     * @returns Promise fulfilled with attribute list. Format: [{id, name, icon, display},...]
     */
    createAttributeList(attributes) {
      const attrDefinitions = _environment.default.APP.attributes;
      if (attributes) {
        const localizedList = attributes.map(attr => {
          const defaultAttrDef = attrDefinitions[+attr.id];
          return {
            id: +attr.id,
            name: this.attributeName(attr, {
              name: defaultAttrDef?.name
            }, 'commonAttributes', attr.id, attr.name),
            icon: attr?.display?.icon || defaultAttrDef?.icon || '',
            display: attr.display
          };
        });
        return localizedList;
      }

      // no attributes from server either -> use env definitions
      return Object.keys(attrDefinitions).map(key => {
        return {
          id: key,
          name: attrDefinitions[key],
          icon: undefined
        };
      });
    }
  });
});