define("commander/pods/components/itinerary-break/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1ctw6N5V",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[10,\"div\"],[14,0,\"grid-row mod-break\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"td type\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"icon\"],[\"coffee\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td time\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"time\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td timediff\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td route\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td status\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isReady\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],null,[[\"icon\"],[\"check-circle\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td description\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"vehicleDetails.itinerary.break\"],null]],[2,\"\\n        \"],[1,[32,0,[\"durationMins\"]]],[2,\"\\n        \"],[1,[30,[36,3],[\"vehicleDetails.itinerary.mins\"],null]],[2,\",\\n        \"],[1,[30,[36,1],[[32,1,[\"item\",\"plannedStart\"]]],null]],[2,\"\\n        -\\n        \"],[1,[30,[36,1],[[32,1,[\"item\",\"plannedEnd\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"hhmm\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "commander/pods/components/itinerary-break/template.hbs"
    }
  });
});