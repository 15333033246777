define("commander/pods/components/itinerary-shift/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { computed } from '@ember/object';

  class ItineraryShift extends _component.default {
    // @computed('args.event.subType')
    get isStart() {
      return this.args.event.subType === 'start';
    }
  }
  _exports.default = ItineraryShift;
});