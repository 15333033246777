define("commander/pods/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SEP_CHAR = ',';
  function qpToArr(qp) {
    if (!qp) {
      return [];
    }
    return qp.split(SEP_CHAR);
  }
  function qpFromArr(arr) {
    return arr.join(SEP_CHAR);
  }
  function arrayAndIndex(qp, key) {
    const values = qpToArr(qp);
    const index = values.indexOf(key);
    return [values, index];
  }
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    queryParams: ['details', 'pinned', 'timeFrame', 'followVehicles'],
    details: null,
    pinned: null,
    timeFrame: null,
    followVehicles: false,
    qpToArr(qp) {
      return qpToArr(qp);
    },
    setQP(type, vals) {
      if (!vals || vals.length === 0) {
        this.set(type, null);
      } else {
        this.set(type, qpFromArr(vals));
      }
    },
    addQP(type, val) {
      const [values, index] = arrayAndIndex(this.get(type), val);
      if (index === -1) {
        values.push(val);
        this.set(type, qpFromArr(values));
      } else {
        this.removeQP(type, val);
      }
    },
    removeQP(type, val) {
      const [values, index] = arrayAndIndex(this.get(type), val);
      if (index >= 0) {
        values.splice(index, 1);
        if (values.length > 0) {
          this.set(type, qpFromArr(values));
        } else {
          this.set(type, null);
        }
      }
    }
  });
});