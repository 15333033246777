define("commander/pods/components/message-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3ynP4t2W",
    "block": "{\"symbols\":[\"@message\",\"@messageClicked\"],\"statements\":[[11,\"tr\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2],[32,1]],null]],null],[12],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,2],[[32,1,[\"sendTime\"]]],null]],[2,\" \"],[1,[30,[36,3],[[32,1,[\"sendTime\"]]],null]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[10,\"div\"],[14,0,\"cutting-text\"],[12],[1,[32,1,[\"message\"]]],[13],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,2],[[32,1,[\"expiration\"]]],null]],[2,\" \"],[1,[30,[36,3],[[32,1,[\"expiration\"]]],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"ddmmyyyy\",\"hhmm\"]}",
    "meta": {
      "moduleName": "commander/pods/components/message-row/template.hbs"
    }
  });
});