define("commander/services/map-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  // TODO may be we should try to move here as much map actions as possible?
  class MapServiceService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "focusedVehicles", null);
      // if any, only these vehicles will be shown on the map
      _defineProperty(this, "selectedVehicles", null);
      // if any, only these vehicles will be highlighted
      _defineProperty(this, "selectedTrip", null);
      _defineProperty(this, "hoveredStop", null);
      // in the itinerary list in general
      _defineProperty(this, "hoveredTrip", null);
      // in the trip table in general
      _defineProperty(this, "hoveredRoute", null);
    }
    // the route will be highlighted

    setFocusedVehicles(numbers) {
      this.set('focusedVehicles', numbers);
    }
    setSelectedVehicles(numbers) {
      this.set('selectedVehicles', numbers);
    }
    setSelectedTrip(trip) {
      this.set('selectedTrip', trip);
    }
    dropSelectedTrip(trip) {
      if (this.selectedTrip && this.selectedTrip.id === trip.id) {
        this.set('selectedTrip', null);
      }
    }
    setHoveredStop(stop) {
      this.set('hoveredStop', stop);
    }
    setHoveredTrip(trip) {
      this.set('hoveredTrip', trip);
    }
    setHoveredRoute(route) {
      this.set('hoveredRoute', route);
    }
  }
  _exports.default = MapServiceService;
});