define("commander/pods/components/capacities-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2w61IpsD",
    "block": "{\"symbols\":[\"cap\"],\"statements\":[[10,\"div\"],[14,0,\"capacities-status\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"orderedCapacities\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"capacity-def \",[30,[36,0],[[32,1,[\"status\"]],[32,1,[\"status\"]]],null]]]],[15,\"title\",[31,[[30,[36,2],[[30,[36,1],[\"commonCapacities.\",[32,1,[\"name\"]]],null]],null]]]],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"icon\"],[[32,1,[\"icon\"]]]]]],[2,\"\\n            \"],[10,\"span\"],[14,0,\"capacity-count\"],[12],[1,[32,1,[\"count\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"t\",\"fa-icon\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "commander/pods/components/capacities-status/template.hbs"
    }
  });
});