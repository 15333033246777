define("commander/mixins/data-sorter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const comparators = {
    number: (a, b, reverse) => {
      const aSafe = isNaN(a) ? -Infinity : a;
      const bSafe = isNaN(b) ? -Infinity : b;
      return reverse ? bSafe - aSafe : aSafe - bSafe;
    },
    string: (a, b, reverse) => {
      const aSafe = a || '';
      const bSafe = b || '';
      return aSafe.localeCompare(bSafe) * (reverse ? -1 : 1);
    },
    complex: (a, b, reverse) => {
      return `${a}`.localeCompare(`${b}`, undefined, {
        numeric: true,
        sensitivity: 'base'
      }) * (reverse ? -1 : 1);
    },
    date: (a, b, reverse) => {
      const aSafe = a ? a.getTime() : 0;
      const bSafe = b ? b.getTime() : 0;
      return (aSafe - bSafe) * (reverse ? -1 : 1);
    }
  };
  var _default = _exports.default = Ember.Mixin.create({
    _isSameSort(by, type) {
      return by === this.get('_lastSort.by') && type === this.get('_lastSort.type');
    },
    _shouldReverse(by, type) {
      const sameSort = this._isSameSort(by, type);
      if (!sameSort) {
        return false;
      }
      const lastrev = this.get('_lastSort.reversed');
      return !lastrev;
    },
    sortAgain() {
      const lastSort = this._lastSort;
      this.sort(lastSort.by, lastSort.type, true);
    },
    sort(by, type, again) {
      let reversed = !!this.get('_lastSort.reversed');
      if (!again) {
        reversed = this._shouldReverse(by, type);
      }
      let toBeSorted = this.get(this.toBeSorted);

      // remember the sort criteria
      this.set('_lastSort', {
        by,
        type,
        reversed
      });
      if (toBeSorted) {
        toBeSorted = this.filter(toBeSorted.toArray());
        this.set(this.sortedResult, toBeSorted.sort((a, b) => {
          if (a && b) {
            return comparators[type](a.get(by), b.get(by), reversed);
          }
          return 0;
        }));
      }
    },
    filter(toBeFiltered) {
      // this.appliedFilters containing object should have:
      // type - value should match property name of the object that is filtered
      // value - search value to compare for, can be an array
      const appliedFilters = (this.appliedFilters || []).reduce((a, i) => {
        if (!a[i.type]) {
          a[i.type] = [];
        }
        a[i.type].push(i.value);
        return a;
      }, {});

      // iterate through each filter and check if an appropriate property equals to one of the values of the filter
      // i - each object in array to be filtered, e.g. route
      // prop - key from applied filters list, generated above from original appliedFilters type
      // filteredProp - value (or object) from the object filtered
      return Object.entries(appliedFilters).length === 0 ? toBeFiltered : toBeFiltered.filter(i => Object.keys(appliedFilters).reduce(
      // test each filter for the row object (i)
      (acc, prop) => {
        const filteredProp = i.get(prop); // value from object

        if (Array.isArray(filteredProp)) {
          if (filteredProp.length === 0) {
            return false;
          }
          if (prop === 'capacityConfigs') {
            const unitIds = filteredProp.reduce((idsAcc, arrayItem) => {
              // for capacities it is nested array
              if (Array.isArray(arrayItem)) {
                const ids = arrayItem.filter(c => c.count > 0).map(id => id.unitId);
                return [...idsAcc, ...ids];
              } else {
                return [...idsAcc, arrayItem.id];
              }
            }, []);
            return acc && appliedFilters[prop].every(props => unitIds.includes(+props)); // match each capacity search option
          }
          const lowercaseProp = filteredProp.map(props => props.toLowerCase());
          return acc && appliedFilters[prop].some(props => lowercaseProp.some(objectProp => objectProp.includes(props.toLowerCase())));
        } else if (filteredProp instanceof Object) {
          // filtered property is object
          return acc && appliedFilters[prop].some(value => filteredProp[value]);
        } else {
          // string filtering
          return acc && appliedFilters[prop].some(value => `${filteredProp}`.toLowerCase().includes(`${value}`.toLowerCase()));
        }
      }, true));
    },
    actions: {
      sort(by, type) {
        this.sort(by, type);
      }
    }
  });
});