define("commander/services/store", ["exports", "@ember-data/store"], function (_exports, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _store.default.extend({
    // Ember DS will call updateId after store.save() http response received. If a record with
    // the same id exist -> exception would be thrown -> this fix to avoid the exception.
    updateId: function (internalModel, data) {
      let existingInternalModel = this._existingInternalModelForId(internalModel.modelName, data.id + '');
      // If record already added via data-update service ->
      // unload it and add the one from http response
      if (!Ember.isNone(existingInternalModel)) {
        this.unloadRecord(existingInternalModel);
      }
      return this._super(...arguments);
    }
  });
});