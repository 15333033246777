define("commander/helpers/translate-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.translateKey = translateKey;
  /**
   * Helper for translating e.g. contracts and labels.
   * Both translations are fetched from garage in simple format as
   * array items with properties key and name.
   *
   * @param {*} params
   *   param[0] - key as string text
   *   param[1] - translations in form: [{key: 'abc', name: 'Abc'}, {key: 'efg', name: 'Efg'}]
   *   param[2] - boolean for including key in addition to returned value. true - include, false - not
   * @returns name as text
   */
  function translateKey(params) {
    const key = params[0];
    const translations = params[1];
    const includeKey = params[2];
    const found = (translations || []).find(c => c.key === key);
    return found ? `${found.name}${includeKey ? ` (${key})` : ''}` : key;
  }
  var _default = _exports.default = Ember.Helper.helper(translateKey);
});