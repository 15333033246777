define("commander/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    pick: 'Pick',
    drop: 'Drop',
    navbar: {
      menu: {
        logout: 'Logout',
        shifts: 'All shifts',
        settings: 'Settings'
      },
      login: 'Login'
    },
    settings: {
      title: 'Settings',
      version: 'Version',
      generateBreak: 'Automatically generate break when a shift is created',
      lastWsMsg: 'Last WS msg',
      language: 'Language',
      event: 'Event',
      highlight: 'Highlight',
      notify: 'Notify',
      tripLate: 'Trip late',
      vehiclePing: 'Vehicle ping late',
      dispatchFail: 'Dispatch unsuccessful'
    },
    languageSelect: {
      language: {
        fi: 'Suomi',
        en: 'English'
      }
    },
    datetime: {
      ranges: {
        title: 'Time Range',
        search: 'Search',
        today: 'Today',
        tomorrow: 'Tomorrow',
        yesterday: 'Yesterday',
        next2days: 'Next 2 days',
        next30minutes: 'Next 30 minutes',
        next1hours: 'Next hour',
        next2hours: 'Next 2 hours',
        next3hours: 'Next 3 hours',
        apply: 'Apply',
        cancel: 'Cancel',
        from: 'From',
        to: 'To',
        hours: 'Hours',
        days: 'Days'
      }
    },
    shifts: {
      title: 'All Shifts'
    },
    routeList: {
      title: 'Routes',
      id: 'Route',
      vehicle: 'Vehicle',
      status: 'Status',
      tripCount: 'Trips',
      start: 'Start',
      end: 'End',
      origin: 'Origin',
      destination: 'Destination',
      municipality: 'Municipality',
      distance: 'Km',
      routeStatus: {
        notstarted: 'Not started',
        started: 'Started',
        paused: 'Paused',
        failed: 'Failed',
        offering: 'Offering',
        accepted: 'Assigned',
        rejected: 'Rejected',
        inProgress: 'In progress',
        completed: 'Completed',
        manual: 'Manual dispatch'
      },
      filter: {
        appliedFilters: 'Applied filters',
        id: 'Route',
        routeStatus: 'Status',
        capacities: 'Capacity',
        attributes: 'Attribute',
        vehicleNumber: 'Vehicle',
        municipality: 'Municipality',
        isLate: 'Late routes',
        noVehicle: 'No vehicle',
        startAddressFull: 'Origin',
        endAddressFull: 'Destination',
        contract: 'Contract'
      }
    },
    tripList: {
      title: 'Trips',
      id: 'Trip',
      status: 'Status',
      route: 'Route',
      vehicle: 'Vehicle',
      passenger: 'Rider',
      origin: 'Origin',
      destination: 'Destination',
      pickupTime: 'Pickup',
      dropoffTime: 'Dropoff',
      tripStatus: {
        noShow: 'No show',
        onBoard: 'Passenger on board',
        finished: 'Completed',
        arrivingPickup: 'Arriving to pickup',
        notStarted: 'Not started'
      },
      filter: {
        id: 'Trip',
        tripStatus: 'Status',
        routeId: 'Route',
        vehicleNumber: 'Vehicle',
        formattedPickAddress: 'Origin',
        formattedDropAddress: 'Destination',
        contract: 'Contract'
      },
      unscheduleConfirm: {
        title: 'Are You Sure?',
        text: 'You are about to unschedule trips, are you sure?',
        yes: 'Yes',
        no: 'Cancel'
      },
      deleteTripConfirm: {
        title: 'Cancel trip?',
        yes: 'Yes',
        no: 'No',
        reason: 'Cancel reason',
        description: 'Additional info'
      }
    },
    vehicleList: {
      title: 'Vehicles',
      id: '#',
      name: 'CallID',
      seats: 'Seats',
      shift: 'Shift',
      filter: {
        number: 'Number',
        name: 'Name',
        attributes: 'Attributes',
        capacities: 'Capacities',
        contract: 'Contract',
        labels: 'Label'
      }
    },
    messenger: {
      title: 'Messages',
      vehicles: 'Vehicles',
      vehiclesHint: 'Select vehicle or enter manually. Supported syntax: "1,2-5,6" or "2-5" or "1 2-5 6" etc.',
      expiration: 'Expiration',
      message: 'Message',
      send: 'Send',
      success: 'Message sent',
      fail: 'Failed to send message',
      hours: '{{count}} h',
      custom: 'Select from calendar...',
      sent: 'Sent',
      activeMessages: 'Active Messages'
    },
    tripDetails: {
      title: 'Trip {{tripId}}',
      route: 'Route',
      vehicle: 'Vehicle',
      sharing: 'Sharing',
      sharingForbidden: 'Forbidden',
      sharingAllowed: 'Allowed',
      price: 'Price',
      totalPrice: 'Trip’s total price',
      excessPrice: 'Trip’s excess price',
      extraFees: 'Extra fees',
      status: 'Status',
      externalId: 'External id',
      requestedTime: 'Requested',
      promisedPick: 'Promised pick',
      promisedDrop: 'Promised drop',
      estimatedPick: 'Estimated pick',
      estimatedDrop: 'Estimated drop',
      plannedTime: 'Planned',
      promisedTime: 'Promised',
      resultTime: 'Actual',
      eta: 'ETA',
      pick: 'Pick',
      drop: 'Drop',
      anchor: {
        type: 'Anchor',
        pick: 'pick',
        drop: 'drop'
      },
      origin: 'Origin',
      destination: 'Destination',
      originInfo: 'Origin info',
      destinationInfo: 'Destination info',
      additionalInfo: 'Info',
      driverInfo: 'Driver info',
      driverInfoTitles: {
        kyyti: 'Driver info',
        phoneNumber: 'Phone',
        serviceLevel: 'Service level'
      },
      history: {
        created: 'Created',
        updated: 'Last Updated',
        deleted: 'Deleted'
      },
      contract: 'Contract'
    },
    routeDetails: {
      title: 'Route {{routeId}}',
      vehicle: 'Vehicle',
      externalId: 'External id',
      price: 'Route’s total price',
      stop: {
        status: 'Status',
        trip: 'Trip',
        type: 'Type',
        plannedTime: 'Planned',
        promisedTime: 'Promised',
        eta: 'ETA'
      },
      unassignVehicleConfirm: {
        title: 'Are You Sure?',
        text: 'You are about to unassign vehicle, are you sure?',
        yes: 'Yes',
        no: 'Cancel'
      },
      startDispatchConfirm: {
        title: 'Start Dispatch?',
        text: 'Start dispatch for selected routes?'
      },
      forceWithoutConstraints: {
        title: 'Vehicle not suitable for the route',
        text: 'Do you REALLY want to force route to the vehicle',
        yes: 'Yes',
        no: 'No, I no not'
      },
      seen: {
        title: 'Driver Acknowledge',
        new: 'Not seen',
        update: 'Update not seen',
        acked: 'Acknowledged'
      },
      illegalConstraints: {
        title: 'Illegal Constraint:',
        constraints: {
          StartTimeWindow: 'StartTimeWindow',
          TripTimeWindows: 'TripTimeWindows',
          StopTime: 'StopTime',
          AreaTime: 'AreaTime',
          RouteExtend: 'RouteExtend',
          MaxEmptyDistance: 'MaxEmptyDistance',
          MaxEmptyDuration: 'MaxEmptyDuration',
          PickDropOrder: 'PickDropOrder',
          DrivingOrder: 'DrivingOrder',
          DirectTime: 'DirectTime',
          TravelLength: 'TravelLength',
          RouteTripContract: 'RouteTripContract',
          VehicleRouteContract: 'VehicleRouteContract',
          Capacity: 'Capacity',
          CapacityLifo: 'CapacityLifo',
          Attributes: 'Attributes',
          IncompatibleAttributes: 'IncompatibleAttributes',
          RideSharing: 'RideSharing',
          Shift: 'Shift',
          RouteChain: 'RouteChain'
        }
      }
    },
    vehicleDetails: {
      title: 'Vehicle {{vehicleNumber}}',
      shifts: 'Shifts',
      breaks: 'Breaks',
      locationTime: 'Last location',
      version: 'Version',
      name: 'Name',
      number: 'Vehicle number',
      externalId: 'Ext vehicle number',
      operator: 'Operator',
      phoneNumber: 'Phone',
      dispatchArea: 'Dispatch area',
      dispatchPriority: 'Dispatch priority',
      priceFactor: 'Price factor',
      useShift: 'Use shift',
      contract: 'Contract',
      labels: 'Labels',
      yes: 'yes',
      no: 'no',
      more: 'more',
      less: 'less',
      itinerary: {
        title: 'Itinerary',
        event: 'Event',
        trip: 'Trip',
        route: 'Route',
        address: 'Address',
        load: 'Load',
        break: 'Break',
        mins: 'mins',
        shiftStart: 'Shift start',
        shiftEnd: 'Shift end',
        export: 'Export to CSV',
        stopPerform: {
          title: 'Are you sure?',
          text: 'Perform the stop?',
          yes: 'Yes',
          no: 'No'
        },
        stopNoShow: {
          title: 'Are you sure?',
          text: 'Stop is no show?',
          yes: 'Yes',
          no: 'No'
        }
      }
    },
    messageDetails: {
      title: 'Message',
      sendTime: 'Sent',
      expirationTime: 'Expiration',
      message: 'Message',
      vehicles: 'Vehicles'
    },
    breakList: {
      vehicle: 'Vehicle',
      start: 'Period start',
      end: 'Period end',
      duration: 'Duration',
      removeConfirm: {
        title: 'Are You Sure?',
        text: 'You are about to remove break from vehicle {{vehicleNumber}}, are you sure?',
        yes: 'Yes',
        no: 'Cancel'
      },
      errors: {
        add: {
          'vehicle has no shift for the given time period': "Vehicle doesn't have a shift for the given time range.",
          'break_length_too_large - VehicleBreak.length': 'Break duration exceeds the time range.',
          invalid_added_break_shift_route_chain: 'Break has an invalid time range.'
        },
        remove: {
          break_not_found: 'Break cannot be removed anymore.',
          no_break_id: 'Break cannot be removed anymore.'
        }
      }
    },
    shiftList: {
      vehicle: 'Vehicle',
      start: 'Starts',
      end: 'Ends',
      empty: 'No results. Search by typing vehicle.',
      vehiclesHint: 'Select vehicle or enter manually. Supported syntax: "1,2-5,6" or "2-5" or "*" to list all.',
      copy: {
        title: 'Copy shift',
        chooseDates: 'Choose dates',
        submit: 'Copy'
      },
      removeConfirm: {
        title: 'Are You Sure?',
        text: 'You are about to remove shift from vehicle {{vehicleNumber}}, are you sure?',
        yes: 'Yes',
        no: 'Cancel'
      },
      errors: {
        add: {
          unknown: 'Unable to add new shift.',
          'shift start time is later than end time': 'Start time is later than end time.',
          vehicle_overlapping_shifts: 'Vehicle {{vehicle}} has overlapping shifts at {{startTime}} - {{endTime}}.',
          'shift_invalid_start_end - VehicleShift.end': 'Shift has illegal start and end times',
          add_shift_routes_no_shift: 'There are existing routes for the vehicle without shifts. Remove routes first.',
          'vehicle has already shift for the given time period': 'Vehicle has already shift for the given time period'
        },
        update: {
          'shift start time is later than end time': 'Start time is later than end time.',
          vehicle_overlapping_shifts: 'Vehicle has overlapping shifts.',
          vehicle_shift_has_routes: 'Vehicle has work that prevents the change.',
          invalid_updated_shift_route_chain: 'Vehicle has work that prevents the change.',
          "shift's existing breaks fall outside of new shift times": "Shift's existing breaks fall outside of new shift times!"
        },
        remove: {
          vehicle_shift_has_routes: 'Vehicle has work in the removed shift.',
          shift_not_found: 'Unable to remove shift that is in the past.',
          shift_not_empty: 'Vehicle has either jobs or breaks in the shift.'
        },
        copy: {
          loadFailed: 'Unable to load the shift from the server.'
        },
        addBreak: {
          unknown: 'Adding a break automatically for the shift failed. Please add break manually.'
        }
      }
    },
    map: {
      vehicleDetails: {
        title: 'Vehicle {{vehicleNumber}}',
        locationTime: 'Last location'
      }
    },
    notification: {
      newTrip: 'There is a new trip.',
      newTrips: 'There are {{newTrips}} new trips.',
      tripLate: 'Trip {{tripId}} is late!',
      vehicleAbsent: 'Vehicle {{vehicleNumber}} is absent!',
      dispatchFail: 'Dispatch failed for route {{routeId}}',
      routeCreateOk: 'Route creation was successful',
      routeCreateFail: 'Route could not be created',
      errorOccurred: 'Error occurred'
    },
    commonAttributes: {
      title: 'Attributes',
      1600: 'Test vehicle',
      1605: 'Child seat',
      1606: 'Baby seat',
      1619: 'Bus',
      1601: 'Station wagon',
      1618: 'Sedan',
      1614: 'Paratransit',
      1612: 'Lowrider',
      1504: 'Pet friendly',
      1621: 'Non smoking',
      1613: 'Stretcher',
      1616: 'Stairclimber',
      1628: 'Front seat',
      1615: 'Wheelchair lift',
      1690: 'Alcometer',
      1627: 'Electric wheelchair',
      1632: 'Multiple wheelchairs',
      1636: "Vehicle's wheelchar",
      9001: 'Anti-infection shield',
      1506: 'Female driver',
      1507: 'Male driver'
    },
    commonCapacities: {
      title: 'Capacities',
      ambulatory: 'Ambulatory',
      wheelchair: 'Wheelchair',
      luggage: 'Luggage',
      'assistive-device': 'Assistive Device',
      stretcher: 'Stretcher',
      'front-seat': 'Front Seat',
      running: 'Fast ambulatory'
    },
    dispatchStatus: {
      notstarted: 'Dispatch not started',
      started: 'Dispatch started',
      paused: 'Dispatch paused',
      failed: 'Dispatch failed',
      offering: 'Offering to vehicle',
      accepted: 'Offer accepted',
      rejected: 'Offer rejected'
    },
    offer: {
      title: 'Offers',
      titleAccepted: 'Accepted offers',
      titleCandidates: 'Candidates offers',
      column: {
        time: 'Time',
        vehicle: 'Vehicle',
        assignedVehicle: 'Assigned',
        candidates: 'Candidates',
        route: 'Route',
        result: 'Result',
        reason: 'Reason',
        rank: 'Rank'
      },
      status: {
        accepted: 'Accepted',
        rejected: 'Rejected'
      },
      reason: {
        'ds-all-suitable-vehicles-rejected': 'ds-all-suitable-vehicles-rejected',
        'vg-vehicle-not-found': 'vg-vehicle-not-found'
      }
    },
    action: {
      schedule: 'Schedule trip',
      unschedule: 'Unschedule trip',
      unscheduleRoute: 'Unschedule route',
      createRoute: 'Create route',
      deleteTrip: 'Delete trip',
      startDispatch: 'Start dispatch',
      cancelDispatch: 'Stop dispatch',
      assignVehicle: 'Assign vehicle',
      unassignVehicle: 'Unassign vehicle',
      findCandidates: 'Candidates',
      selectedCount: '{{count}} pcs',
      performStopOk: 'Mark as performed',
      performStopNoShow: 'Mark as no-show'
    },
    actionValidation: {
      noSelection: 'nothing selected',
      alreadyStarted: 'already started',
      alreadyScheduled: 'already scheduled',
      alreadyUnscheduled: 'already unscheduled',
      alreadyAssigned: 'already assigned',
      alreadyUnassignedTrip: 'Already contains trip without a vehicle!',
      alreadyUnassignedRoute: 'Already contains route without a vehicle!',
      unscheduleFirst: 'unable to delete scheduled trip(s)',
      onlyOneAssignment: 'only one assignment at a time!',
      tripWithoutRoute: 'trip without a route selected!'
    },
    dispatching: {
      option: {
        'take-next': 'Original dispatch plan',
        noop: 'Do nothing',
        'cancel-route': 'Cancel route'
      },
      failureAction: 'Dispatch failure next action',
      duration: 'Duration (minutes)',
      excludedVehicles: 'Excluded vehicles',
      start: 'Start',
      cancel: 'Cancel',
      errors: {
        durationRequired: 'Duration required'
      }
    },
    candidatesDetails: {
      title: 'Route {{routeId}} candidates',
      roundTitle: 'Dispatch round {{dispatchRound}} (priority group {{priorityGroup}})',
      number: 'Vehicle',
      distance: 'Distance',
      startTime: 'Start time',
      assigning: 'Assigning route to vehicle',
      errors: {
        unableToAssign: 'Unable to assign route'
      }
    },
    label: {
      selectVehicle: 'Select vehicle'
    },
    stopPerform: {
      errors: {
        'Network request failed': 'Network request failed'
      }
    },
    trip: {
      deleteReason: {
        dispatcher: 'Dispatcher',
        customer: 'Customer',
        'no-vehicle': 'No vehicle',
        'double-booking': 'Double booking',
        'no-show': 'No show'
      },
      consignee: 'Consignee required',
      sharingForbidden: 'Sharing forbidden',
      mobileApp: 'Mobile App'
    },
    readOnlyData: {
      title: 'Overflow',
      attribute: 'OF'
    },
    customerLocale: {
      tuomi: {
        tripDetails: {
          excessPrice: 'Trip’s excess price'
        }
      }
    }
  };
});