define("commander/pods/components/detail-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DaJwBNzM",
    "block": "{\"symbols\":[\"@entity\",\"@detailEvent\",\"@hover\"],\"statements\":[[11,\"div\"],[24,0,\"container-fluid detail-panel card card-inverse\"],[4,[38,3],[\"mouseenter\",[32,0,[\"handleMouseEnter\"]]],null],[4,[38,3],[\"mouseleave\",[30,[36,4],[[32,3],[32,1],false],null]],null],[12],[2,\"\\n    \"],[11,\"span\"],[24,0,\"close-button\"],[4,[38,3],[\"click\",[32,0,[\"closeDetail\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"icon\",\"size\"],[\"times\",\"lg\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[32,1,[\"typeName\"]],\"route\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"route\",\"model\",\"detailEvent\"],[[32,1],[35,1],[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[32,1,[\"typeName\"]],\"trip\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"trip-detail\",[],[[\"@trip\",\"@detailEvent\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[32,1,[\"typeName\"]],\"vehicle\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"vehicle\",\"detailEvent\"],[[32,1],[32,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[32,1,[\"typeName\"]],\"message\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"message-detail\",[],[[\"@message\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[32,1,[\"typeName\"]],\"candidates\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"candidates-detail\",[],[[\"@entity\",\"@closePanel\"],[[32,1],[32,0,[\"closeDetail\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"vehicle-detail\",\"model\",\"route-detail\",\"on\",\"fn\",\"fa-icon\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "commander/pods/components/detail-panel/template.hbs"
    }
  });
});