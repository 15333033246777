define("commander/pods/components/vehicle-table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JVgtPqND",
    "block": "{\"symbols\":[\"@vehicle\",\"@vehicleClicked\",\"@onVehicleHover\",\"@onVehicleOut\"],\"statements\":[[11,\"tr\"],[16,0,[31,[[30,[36,0],[[32,0,[\"isDriving\"]],\"is-driving\"],null],\" \",[30,[36,0],[[32,0,[\"isAbsent\"]],\"is-absent\"],null]]]],[4,[38,2],[\"click\",[30,[36,1],[[32,2],[32,1,[\"number\"]]],null]],null],[4,[38,2],[\"mouseenter\",[30,[36,1],[[32,3],[32,1]],null]],null],[4,[38,2],[\"mouseleave\",[30,[36,1],[[32,4]],null]],null],[12],[2,\"\\n    \"],[10,\"th\"],[14,\"scope\",\"row\"],[12],[1,[32,1,[\"number\"]]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"capacities-list\",[],[[\"@capacities\"],[[32,0,[\"capacities\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,0,[\"shift\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "commander/pods/components/vehicle-table-row/template.hbs"
    }
  });
});