define("commander/pods/components/itinerary-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ho76n6Cv",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[10,\"div\"],[14,0,\"grid-row mod-date\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"td description\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"time\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"ddmmyyyy\"]}",
    "meta": {
      "moduleName": "commander/pods/components/itinerary-date/template.hbs"
    }
  });
});