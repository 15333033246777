define("commander/pods/components/sortable-th/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FLW2Ilmn",
    "block": "{\"symbols\":[\"@class\",\"@width\",\"@sortType\",\"@sortBy\",\"@sort\",\"&default\"],\"statements\":[[11,\"th\"],[16,0,[31,[\"sortable \",[30,[36,0],[[32,1],[32,1]],null]]]],[16,5,[30,[36,1],[\"width\",[32,2]],null]],[4,[38,3],[\"click\",[30,[36,2],[[32,5],[32,4],[32,3]],null]],null],[12],[2,\"\\n    \"],[18,6,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"inline-style\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "commander/pods/components/sortable-th/template.hbs"
    }
  });
});