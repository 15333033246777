define("commander/services/data-update", ["exports", "commander/utils/route", "commander/utils/utils", "commander/config/environment", "event-source-polyfill", "fetch"], function (_exports, _route, _utils, _environment, _eventSourcePolyfill, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const READ_ONLY_API_URLS = _environment.default.APP.readOnlyApi.urls;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    auth: Ember.inject.service(),
    uiNotification: Ember.inject.service(),
    displayWindow: Ember.inject.service(),
    dataFilter: Ember.inject.service(),
    vehicleLocationService: Ember.inject.service('vehicle-locations'),
    lastMsgTime: null,
    // Some events only pertain to one user. Use this map to store data identifying such events, like
    // expected event name and parameters in data.
    expectedEvents: {
      'route.create.*': []
    },
    init() {
      this._super(...arguments);
    },
    _fromReadOnlyApi(jsonApi) {
      return {
        id: jsonApi.id,
        readOnly: true,
        ...jsonApi.attributes
      };
    },
    fetchReadOnlyData(filter) {
      if (READ_ONLY_API_URLS.length) {
        READ_ONLY_API_URLS.forEach(url => {
          if (!this.auth.isAuthenticated) {
            Ember.run.later(() => {
              this._fetchReadOnlyData();
            }, 8000);
            return;
          }
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.auth.accessToken}`,
            accept: 'application/vnd.api+json'
          };
          const query = new URLSearchParams(filter).toString();
          (0, _fetch.default)(`${url}/routes?${query}`, {
            method: 'GET',
            headers
          }).then(response => response.json()).then(payload => {
            const msg = {
              newRoutes: payload.data.map(r => this._fromReadOnlyApi(r))
            };
            this._handleTripAndRouteChanges(msg);
          }).then(() => (0, _fetch.default)(`${url}/trips?${query}`, {
            method: 'GET',
            headers
          })).then(response => response.json()).then(payload => {
            const msg = {
              updatedTrips: payload.data.map(r => this._fromReadOnlyApi(r))
            };
            this._handleTripAndRouteChanges(msg);
          }).catch(function (response) {
            // noop
          });
        });
      }
    },
    setupSubscriptions( /*nesClient*/
    ) {
      const subscriptionMapping = {
        '/eta.update': this.routeUpdate.bind(this),
        '/trip.insert.ok': this.routeUpdate.bind(this),
        '/trip.add.ok': this.tripUpdate.bind(this),
        '/trip.update.ok': this.tripUpdate.bind(this),
        '/trip.price': this.tripPrice.bind(this),
        '/trip.unroute.ok': this.routeUpdate.bind(this),
        '/trip.remove.ok': this.tripRemove.bind(this),
        '/trip.set_promised_times': this.routeUpdate.bind(this),
        '/route.add.ok': this.routeUpdate.bind(this),
        '/route.update.ok': this.routeUpdate.bind(this),
        '/route.remove.ok': this.tripRemove.bind(this),
        '/route.assignment': this.routeUpdate.bind(this),
        '/route.unassignment': this.routeUpdate.bind(this),
        '/route.delivered': this.routeDelivered.bind(this),
        '/route.seen': this.routeSeen.bind(this),
        '/route.create.ok': this.routeCreateOk.bind(this),
        '/route.create.failed': this.routeCreateFailed.bind(this),
        '/stop.perform': this.routeUpdate.bind(this),
        '/stop.unperform': this.routeUpdate.bind(this),
        '/route.dispatchStrategy.ok': this.routeUpdate.bind(this),
        // '/break.start': this.printTmpMsg.bind(this),
        '/break.add': this.breakUpdate.bind(this),
        '/break.remove': this.breakUpdate.bind(this),
        '/shift.add': this.shiftUpdate.bind(this),
        '/shift.update': this.shiftUpdate.bind(this),
        '/shift.remove': this.shiftUpdate.bind(this),
        '/viper.pings': this.locationUpdate.bind(this),
        '/viper.message.add': this.messageUpdate.bind(this)
      };
      if (_environment.default.APP.dispatchEvents.listen) {
        //  subscribe to dispatch.* events
        Object.assign(subscriptionMapping, {
          '/dispatch.start': this.dispatchEvent.bind(this, _route.default.DISPATCH_STATUSES.started.id),
          '/dispatch.paused': this.dispatchEvent.bind(this, _route.default.DISPATCH_STATUSES.paused.id),
          '/dispatch.failed': this.dispatchEvent.bind(this, _route.default.DISPATCH_STATUSES.failed.id),
          '/dispatch.offer.start': this.dispatchEvent.bind(this, _route.default.DISPATCH_STATUSES.offering.id),
          '/dispatch.offer.failed': this.dispatchEvent.bind(this, _route.default.DISPATCH_STATUSES.rejected.id),
          '/dispatch.offer.accepted': this.dispatchEvent.bind(this, _route.default.DISPATCH_STATUSES.accepted.id)
        });
      }

      // --- sse events ---
      this._setupSSE(_environment.default.APP.api.url, subscriptionMapping);
      if (READ_ONLY_API_URLS.length) {
        READ_ONLY_API_URLS.forEach(url => this._setupSSE(url, subscriptionMapping));
      }
    },
    /**
     * Setup SSE EventSource.
     * @todo: occasionally (10 mins?) EventSource connection breaks with code 499 even though messages are sent
     *        constantly. Not sure why, but it reconnects immediately.
     *
     * @param {*} apiUrl
     * @param {*} subscriptionMapping Mappings for events received from server.
     */
    _setupSSE(apiUrl, subscriptionMapping) {
      // don't try to open EventSource if not authenticated
      if (!this.auth.isAuthenticated) {
        Ember.run.later(() => {
          this._setupSSE(apiUrl, subscriptionMapping);
        }, 8000);
        return;
      }

      // Need to use a library for EventSource when we are not using cookies for authentication
      // EventSourcePolyfill supports token headers
      const sse = new _eventSourcePolyfill.EventSourcePolyfill(apiUrl + '/events', {
        headers: {
          Authorization: `Bearer ${this.auth.accessToken}`
        }
      });
      sse.addEventListener('update', e => {
        const msg = JSON.parse(e.data);
        // console.log('update msg', msg.type, msg.data);

        // delay slightly to prevent this update arriving before e.g. just created item
        this.set('lastMsgTime', new Date());
        const fn = subscriptionMapping[`/${msg.type}`];
        if (fn) {
          Ember.run.later(() => {
            fn(msg.data);
          }, 500);
        } else {
          console.log('not handling', msg.type); // , msg.data);
        }
      });
      sse.onerror = e => {
        // @todo: consider if data should be fetched manually on errors (but not too ofter)
        console.log(`sse error received (readyState: ${sse.readyState})`, e);

        // if readyState is closed -> need to init connection again.
        // With other states EventSource will reconnect automatically
        if (sse.readyState === _eventSourcePolyfill.EventSourcePolyfill.CLOSED) {
          // 2
          sse.close();
          Ember.run.later(() => {
            this._setupSSE(apiUrl, subscriptionMapping);
          }, 8000);
        }
      };
      sse.onopen = e => {
        console.log('sse open received', e);
      };
    },
    _unloadRecords(type, ids) {
      const store = this.store;
      const removedIds = [];
      ids.forEach(id => {
        const record = store.peekRecord(type, id);
        if (record) {
          store.unloadRecord(record);
          removedIds.push(id);
        }
      });
      return removedIds;
    },
    _pushRecords(type, records) {
      if (records && records.length > 0) {
        this.store.pushPayload({
          data: records.map(i => ({
            type: type,
            id: i.id,
            attributes: i
          }))
        });
      }
      return records.map(i => `${i.id}`);
    },
    _pushRecord(type, record) {
      if (record && record.id) {
        this.store.pushPayload({
          data: {
            type: type,
            id: record.id,
            attributes: record
          }
        });
      }
    },
    // TODO check if it possible to use the general `_pushRecords` function for routs as well. Difference is between `pushPayload` and `push`
    _pushRoutesToStore(records) {
      if (records && records.length > 0) {
        // normalize trips before pushing routes into the store
        records.forEach(r => {
          r.trips.forEach(t => _utils.default.normalizeTrip(t, t.id, r.vehicleNumber || r.vehicle?.number));
          r.stops.forEach(s => _utils.default.normalizeStop(s));
        });
        this.store.push({
          data: records.map(r => ({
            type: 'route',
            id: r.id,
            attributes: r
          }))
        });
      }
      return records.map(i => `${i.id}`);
    },
    breakUpdate(msg) {
      this.shiftUpdate(msg, true);
    },
    locationUpdate(msg) {
      this.vehicleLocationService.updateLocations(msg);
    },
    updateShiftsAndBreaks(shifts) {
      const shiftsToAdd = shifts.filter(i => this._isTimeBetweenTimeWindow(i.startTime, i.endTime));
      this._pushRecords('shift', shiftsToAdd);
      shiftsToAdd.forEach(item => this._saveShiftBreaks(item));
    },
    shiftUpdate(msg, breakUpdate = false) {
      if (msg.newShifts && msg.newShifts.length > 0) {
        this.updateShiftsAndBreaks(msg.newShifts);
      }
      if (msg.modifiedShifts && msg.modifiedShifts.length > 0) {
        this.updateShiftsAndBreaks(msg.modifiedShifts);
      }
      if (msg.removedShifts && msg.removedShifts.length > 0) {
        this._unloadRecords('shift', msg.removedShifts);
      }
      if (msg.removedBreaks && msg.removedBreaks.length > 0) {
        this._unloadRecords('break', msg.removedBreaks);
      }
      this.dataFilter.updateShifts(msg.vehicleIDs, breakUpdate);
    },
    _saveShiftBreaks(shift) {
      return this._pushRecords('break', shift.breaks);
    },
    routeUpdate(msg) {
      this._handleTripAndRouteChanges(msg);
    },
    _handleNewRoutes(newRoutes) {
      const routesToAdd = newRoutes.filter(i => this._isTimeBetweenTimeWindow(i.startTime, i.endTime));
      return this._pushRoutesToStore(routesToAdd);
    },
    _handleRouteUpdates(modifiedRoutes) {
      const routesToAdd = [];
      const routesToUpdate = [];
      modifiedRoutes.forEach(i => {
        if (this.store.peekRecord('route', i.id)) {
          routesToUpdate.push(i);
        } else if (this._isTimeBetweenTimeWindow(i.startTime, i.endTime)) {
          routesToAdd.push(i);
        }
      });

      // note: updating with push requires also removed properties to be set to null,
      // otherwise old values are not replaced
      this._pushRoutesToStore([...routesToAdd, ...routesToUpdate]);
      return {
        updateRouteIds: routesToUpdate.map(r => `${r.id}`),
        newRouteIds: routesToAdd.map(r => `${r.id}`)
      };
    },
    _saveRouteTrips(routes) {
      let tripIds = [];
      routes.forEach(r => {
        const trips = r.get('trips');
        trips.forEach(t => _route.default.setTripPath(r, t));
        tripIds = tripIds.concat(this._pushRecords('trip', trips));
      });
      return tripIds;
    },
    _isTimeBetweenTimeWindow(startTimeString, endTimeString) {
      const win = this.displayWindow.getWindow();
      const startTimeMillis = new Date(startTimeString).getTime();
      const endTimeMillis = new Date(endTimeString).getTime();
      return startTimeMillis <= win.end.getTime() && endTimeMillis >= win.start.getTime();
    },
    _isRouteBetweenTimeWindow(routeId) {
      if (!routeId) {
        return false;
      }
      const route = this.store.peekRecord('route', routeId);
      return this._isTimeBetweenTimeWindow(route.startTime, route.endTime);
    },
    tripPrice(msg) {
      const modifiedPrices = msg.modifiedPrices;
      if (modifiedPrices && modifiedPrices.length) {
        msg.modifiedPrices.forEach(update => {
          let trip = this.store.peekRecord('trip', update.tripId);
          if (trip) {
            trip.set('price', update.price);
          }
        });
      }
    },
    tripUpdate(msg) {
      const newIds = this._handleNewTrips(msg);
      let updatedTripIds = this._handleUpdatedTrips(msg.updatedTrips);

      // TODO: `updateTrips` does not accept any arguments, so there is no need in collecting IDs and passing them to the function
      this.dataFilter.updateTrips(newIds, updatedTripIds, []);
    },
    // handle trip remove related modifications
    tripRemove(msg) {
      this._handleTripAndRouteChanges(msg);
    },
    _handleUpdatedTrips(trips) {
      let updatedIds = [];
      if (trips && trips.length > 0) {
        // TODO: trips that are not in DS will be ignored. Not sure if this is a correct behaviour...
        const updates = trips.filter(i => this.store.peekRecord('trip', i.id));
        // readOnly API return also trip.vehicleNumber if trip on route and route.vehicleNumber
        updates.forEach(i => _utils.default.normalizeTrip(i, i.id, i.vehicleNumber));
        updatedIds = this._pushRecords('trip', updates);
      }
      return updatedIds;
    },
    // handler for new trips
    _handleNewTrips(msg) {
      let newIds = [];
      if (msg.newTrips && msg.newTrips.length > 0) {
        const tripsToAdd = msg.newTrips.filter(i => this._isTimeBetweenTimeWindow(i.requestedTime, i.requestedTime) || this._isRouteBetweenTimeWindow(i.routeId ? i.routeId + '' : null));
        newIds = tripsToAdd.map(i => `${i.id}`);

        // readOnly API return also trip.vehicleNumber if trip on route and route.vehicleNumber
        tripsToAdd.forEach(t => _utils.default.normalizeTrip(t, t.id, t.vehicleNumber));
        if (tripsToAdd.length) {
          if (_environment.default.APP.trip.notification) {
            this.uiNotification.playAudio();
            this.uiNotification.showDesktopNotification(this.i18n.t(msg.newTrips.length > 1 ? 'notification.newTrips' : 'notification.newTrip', {
              newTrips: msg.newTrips.length
            }));
          }
          this._pushRecords('trip', tripsToAdd);
        }
      }
      return newIds;
    },
    // common handler for trip and route changes
    _handleTripAndRouteChanges(msg) {
      let removedTripIds = [];
      let removedRouteIds = [];
      let newTripIds = [];
      let updatedTripIds = [];
      let newRouteIds = [];
      let updatedRouteIds = [];

      // removed routes
      if (msg.removedRoutes && msg.removedRoutes.length > 0) {
        removedRouteIds = this._unloadRecords('route',
        // depending event version, we could have array of objects or just ids.
        msg.removedRoutes.map(r => r.id || r));
      }

      // removed trips
      if (msg.removedTrips && msg.removedTrips.length > 0) {
        removedTripIds = this._unloadRecords('trip', msg.removedTrips.map(i => `${i.id}`));
      }

      // modified routes (may contain new trips)
      if (msg.modifiedRoutes && msg.modifiedRoutes.length > 0) {
        // handle modified routes, modified can sometimes be new to client e.g. when old route times chance
        const updates = this._handleRouteUpdates(msg.modifiedRoutes);
        newRouteIds = newRouteIds.concat(updates.newRouteIds);
        updatedRouteIds = updatedRouteIds.concat(updates.updateRouteIds);
      }

      // updated trips
      updatedTripIds = this._handleUpdatedTrips(msg.unroutedTrips);

      // new routes
      if (msg.newRoutes && msg.newRoutes.length > 0) {
        // handle new routes
        newRouteIds = newRouteIds.concat(this._handleNewRoutes(msg.newRoutes));
      }

      // new trips
      if (msg.newTrips && msg.newTrips.length > 0) {
        const newIds = this._handleNewTrips(msg);
        newTripIds = [...newTripIds, ...newIds];
      }

      // modified trips
      if (msg.updatedTrips && msg.updatedTrips.length > 0) {
        let updatedIds = this._handleUpdatedTrips(msg.updatedTrips);
        updatedTripIds = [...updatedTripIds, ...updatedIds];
      }

      // all updated or new routes need to save also trips separately for trip-listing
      const allModifiedRouteIds = [...newRouteIds, ...updatedRouteIds];
      const filteredRoutes = allModifiedRouteIds.map(i => this.store.peekRecord('route', i)).filter(Boolean);
      newTripIds = newTripIds.concat(this._saveRouteTrips(filteredRoutes));

      // TODO: `updateTrips` does not accept any arguments, so there is no need in collecting IDs and passing them to the function
      const modifiedRouteIds = allModifiedRouteIds.map(i => `${i}`);
      this.dataFilter.updateTrips(newTripIds, updatedTripIds, removedTripIds);
      this.dataFilter.updateRoutes(modifiedRouteIds, removedRouteIds);
    },
    dispatchEvent(status, msg) {
      const route = this.store.peekRecord('route', msg.route.id);
      if (route) {
        route.setDispatchStatus(status, msg.vehicleNumber);
      }
    },
    messageUpdate( /*msg*/
    ) {
      // just re-fetch all messages
      this.dataFilter.updateMessages();
    },
    routeDelivered(msg) {
      for (const id of msg) {
        const route = this.store.peekRecord('route', id);
        if (route) {
          route.set('delivered', true);
        }
      }
    },
    routeSeen(msgs) {
      for (const msg of msgs) {
        const route = this.store.peekRecord('route', msg.routeId);
        if (route) {
          this._pushRecord('route', {
            id: msg.routeId,
            seen: msg.seen
          });
        }
      }
    },
    routeCreateOk(msg) {
      if (this.expectedEvents['route.create.*'].includes(msg.refId)) {
        this.uiNotification.showDesktopNotification(this.i18n.t('notification.routeCreateOk'));
      }
      this._handleTripAndRouteChanges(msg);
    },
    routeCreateFailed(msg) {
      if (this.expectedEvents['route.create.*'].includes(msg.refId)) {
        this.uiNotification.showDesktopNotification(this.i18n.t('notification.routeCreateFail'));
      }
    }
  });
});