define("commander/pods/components/detail-property-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I9ReaVoT",
    "block": "{\"symbols\":[\"&default\",\"@title\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"row detail-property-row \",[30,[36,0],[[32,0,[\"isHidden\"]],\"is-hidden\"],null]]]],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"col-sm-5\"],[12],[1,[30,[36,1],[[32,2]],null]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"col-sm-7\"],[12],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[18,1,null]],\"parameters\":[]},{\"statements\":[[1,[32,0,[\"formattedValue\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "commander/pods/components/detail-property-row/template.hbs"
    }
  });
});