define("commander/pods/components/attribute-icons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ddNH/D5r",
    "block": "{\"symbols\":[\"attr\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"visibleAttributes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"text\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[[32,1,[\"class\"]],\" attribute-text\"]]],[15,\"title\",[31,[[32,1,[\"name\"]]]]],[12],[2,\"\\n            \"],[1,[32,1,[\"text\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[[32,1,[\"border\"]],\"attribute-border\"],null]]]],[15,\"title\",[31,[[32,1,[\"name\"]]]]],[12],[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[[31,[[32,1,[\"icon\"]]]],[31,[[32,1,[\"prefix\"]]]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "commander/pods/components/attribute-icons/template.hbs"
    }
  });
});