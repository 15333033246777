define("commander/helpers/is-object-prop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IsObjectProperty extends Ember.Helper {
    compute([object, property]) {
      return Object.prototype.hasOwnProperty.call(object, property) && !!object[property];
    }
  }
  _exports.default = IsObjectProperty;
});