define("commander/helpers/local-time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.localDateTimeString = localDateTimeString;
  _exports.localTimeDateString = localTimeDateString;
  function localDateTimeString(time) {
    return time ? moment(time).format('L LT') : '';
  }
  function localTimeDateString(time) {
    return time ? moment(time).format('LT L') : '';
  }
  var _default = _exports.default = Ember.Helper.helper(params => localDateTimeString(params[0]));
});