define("commander/locales/fi/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    pick: 'Nouto',
    drop: 'Kohde',
    navbar: {
      menu: {
        logout: 'Kirjaudu ulos',
        shifts: 'Kaikki ajovuorot',
        settings: 'Asetukset'
      },
      login: 'Kirjaudu'
    },
    settings: {
      title: 'Asetukset',
      version: 'Versio',
      generateBreak: 'Luo tauko automaattisesti kun ajovuoro luodaan',
      lastWsMsg: 'Viimeisin WS viesti',
      language: 'Kieli',
      event: 'Tapahtuma',
      highlight: 'Korostus',
      notify: 'Hälytys',
      tripLate: 'Tilaus myöhässä',
      vehiclePing: 'Sijainti myöhässä',
      dispatchFail: 'Autonvalinta epäonnistunut'
    },
    languageSelect: {
      language: {
        fi: 'Suomi',
        en: 'English'
      }
    },
    datetime: {
      ranges: {
        title: 'Aikaväli',
        search: 'Aikaväli',
        today: 'Tänään',
        tomorrow: 'Huomenna',
        yesterday: 'Eilen',
        next2days: 'Seuraavat 2 päivää',
        next30minutes: 'Seuraavat 30 minuuttia',
        next1hours: 'Seuraava tunti',
        next2hours: 'Seuraavat 2 tuntia',
        next3hours: 'Seuraavat 3 tuntia',
        apply: 'Ok',
        cancel: 'Peru',
        from: 'Mistä',
        to: 'Mihin',
        hours: 'Tuntia',
        days: 'Päivää'
      }
    },
    shifts: {
      title: 'Ajovuorot'
    },
    routeList: {
      title: 'Reitit',
      id: 'Reitti',
      vehicle: 'Auto',
      status: 'Tila',
      tripCount: 'Tilauksia',
      start: 'Lähtö',
      end: 'Loppu',
      origin: 'Nouto-osoite',
      destination: 'Kohdeosoite',
      municipality: 'Kunta',
      distance: 'Km',
      routeStatus: {
        notstarted: 'Ei aloitettu',
        started: 'Autonvalinta aloitettu',
        paused: 'Autonvalinta pysähtynyt',
        failed: 'Autonvalinta epäonnistunut',
        offering: 'Tarjotaan',
        accepted: 'Tarjous hyväksytty',
        rejected: 'Tarjous hylätty',
        inProgress: 'Reitti ajossa',
        completed: 'Reitti valmis',
        manual: 'Autonvalinta pois käytöstä'
      },
      filter: {
        appliedFilters: 'Suodattimet',
        id: 'Reitti',
        routeStatus: 'Tila',
        capacities: 'Kapasiteetit',
        attributes: 'Attribuutit',
        vehicleNumber: 'Auto',
        municipality: 'Kunta',
        isLate: 'Myöhästyneet reitit',
        noVehicle: 'Ei autoa',
        startAddressFull: 'Nouto-osoite',
        endAddressFull: 'Kohdeosoite',
        contract: 'Sopimus'
      }
    },
    tripList: {
      title: 'Tilaukset',
      id: 'Tilaus',
      status: 'Tila',
      route: 'Reitti',
      vehicle: 'Auto',
      passenger: 'Matkustaja',
      origin: 'Nouto-osoite',
      destination: 'Kohdeosoite',
      pickupTime: 'Nouto',
      dropoffTime: 'Kohde',
      tripStatus: {
        noShow: 'Asiakas poissa',
        onBoard: 'Asiakas kyydissä',
        finished: 'Valmis',
        arrivingPickup: 'Saapumassa noutoon',
        notStarted: 'Ei aloitettu'
      },
      filter: {
        id: 'Tilaus',
        tripStatus: 'Tila',
        routeId: 'Reitti',
        vehicleNumber: 'Auto',
        formattedPickAddress: 'Nouto-osoite',
        formattedDropAddress: 'Kohdeosoite',
        contract: 'Sopimus'
      },
      unscheduleConfirm: {
        title: 'Oletko varma?',
        text: 'Poista tilaukset reiteiltä?',
        yes: 'Kyllä',
        no: 'Peruuta'
      },
      deleteTripConfirm: {
        title: 'Perutaanko tilaus?',
        yes: 'Kyllä',
        no: 'Ei',
        reason: 'Perumisen syy',
        description: 'Lisätietoja'
      }
    },
    vehicleList: {
      title: 'Autot',
      id: '#',
      name: 'Kutsu',
      seats: 'Paikkoja',
      shift: 'Ajovuoro',
      filter: {
        number: 'Autonumero',
        name: 'Nimi',
        attributes: 'Attribuutit',
        capacities: 'Kapasiteetit',
        contract: 'Sopimus',
        labels: 'Tunniste'
      }
    },
    messenger: {
      title: 'Viestit',
      vehicles: 'Autot',
      vehiclesHint: 'Valitse autot listasta tai kirjoittamalla esimerkiksi: "1,2-5,6" tai "2-5" tai "1 2-5 6"',
      expiration: 'Voimassa',
      message: 'Viesti',
      send: 'Lähetä',
      success: 'Viesti lähetetty',
      fail: 'Viestin lähetys epäonnistui',
      hours: '{{count}} h',
      custom: 'Valitse kalenterista...',
      sent: 'Lähetetty',
      activeMessages: 'Aktiiviset viestit'
    },
    tripDetails: {
      title: 'Tilaus {{tripId}}',
      route: 'Reitti',
      vehicle: 'Auto',
      sharing: 'Yhdistely',
      sharingForbidden: 'Kielletty',
      sharingAllowed: 'Sallittu',
      price: 'Hinta',
      totalPrice: 'Tilauksen kokonaishinta',
      excessPrice: 'Omavastuu',
      extraFees: 'Lisämaksut',
      status: 'Tila',
      externalId: 'Ulkoinen tunnus',
      requestedTime: 'Toive',
      promisedPick: 'Luvattu nouto',
      promisedDrop: 'Luvattu perillä',
      estimatedPick: 'Arvioitu nouto',
      estimatedDrop: 'Arvioitu perillä',
      plannedTime: 'Suunniteltu',
      promisedTime: 'Luvattu',
      resultTime: 'Toteutunut',
      eta: 'Arvioitu',
      pick: 'Nouto',
      drop: 'Kohde',
      anchor: {
        type: 'Tilauksen tyyppi',
        pick: 'lähtö',
        drop: 'perillä'
      },
      origin: 'Nouto-osoite',
      destination: 'Kohdeosoite',
      originInfo: 'Nouto lisätieto',
      destinationInfo: 'Kohde lisätieto',
      additionalInfo: 'Lisätieto',
      driverInfo: 'Kuljettajalle',
      driverInfoTitles: {
        kyyti: 'Kuljettajalle',
        phoneNumber: 'Puhelin',
        serviceLevel: 'Palveluluokka'
      },
      history: {
        created: 'Luotu',
        updated: 'Päivitetty',
        deleted: 'Deleted'
      },
      contract: 'Sopimus'
    },
    routeDetails: {
      title: 'Reitti {{routeId}}',
      vehicle: 'Auto',
      externalId: 'Ulkoinen tunnus',
      price: 'Reitin kokonaishinta',
      stop: {
        status: 'Tila',
        trip: 'Tilaus',
        type: 'Tyyppi',
        plannedTime: 'Laskettu',
        promisedTime: 'Luvattu',
        eta: 'Arvioitu'
      },
      unassignVehicleConfirm: {
        title: 'Oletko varma?',
        text: 'Olet poistamassa auton reitiltä, oletko varma?',
        yes: 'Kyllä',
        no: 'Peruuta'
      },
      startDispatchConfirm: {
        title: 'Aloitetaanko autonvalinta?',
        text: 'Aloitetaanko autonvalinta valituille reiteille?'
      },
      forceWithoutConstraints: {
        title: 'Auto ei sovi reitille',
        text: 'Haluatko todella pakottaa auton reitille',
        yes: 'Kyllä',
        no: 'En'
      },
      seen: {
        title: 'Kuljettajan kuittaus',
        new: 'Ei nähty',
        update: 'Päivitystä ei nähty',
        acked: 'Nähty'
      },
      illegalConstraints: {
        title: 'Yhteensopimaton:',
        constraints: {
          StartTimeWindow: 'Reitin Aloitus',
          TripTimeWindows: 'Matkan aikaikkuna',
          StopTime: 'Pysähdyksen aika',
          AreaTime: 'Alue-aika',
          RouteExtend: 'Reitin venymä',
          MaxEmptyDistance: 'Tyhjänäajomatka',
          MaxEmptyDuration: 'Tyhjänäajoaika',
          PickDropOrder: 'Nouto-Kohde järjestys',
          DrivingOrder: 'Ajojärjestys',
          DirectTime: 'Suora-aika',
          TravelLength: 'Matkan pituus',
          RouteTripContract: 'Matkan Sopimus',
          VehicleRouteContract: 'Auton Sopimus',
          Capacity: 'Kapasiteetti',
          CapacityLifo: 'Kapasiteettien lastausjärjestys',
          Attributes: 'Varuste',
          IncompatibleAttributes: 'Yhteensopimattomat varusteet',
          RideSharing: 'Yhdistelykielto',
          Shift: 'Ajovuoro',
          RouteChain: 'Reittiketju-ongelma'
        }
      }
    },
    vehicleDetails: {
      title: 'Auto {{vehicleNumber}}',
      shifts: 'Ajovuorot',
      breaks: 'Tauot',
      locationTime: 'Viimeisin sijainti',
      version: 'Versio',
      name: 'Nimi',
      number: 'Autonumero',
      externalId: 'Ulkoinen autonumero',
      operator: 'Operaattori',
      phoneNumber: 'Puhelin',
      dispatchArea: 'Alue',
      dispatchPriority: 'Prioriteetti',
      priceFactor: 'Hintakerroin',
      useShift: 'Ajovuorollinen',
      contract: 'Sopimus',
      labels: 'Tunnisteet',
      yes: 'kyllä',
      no: 'ei',
      more: 'lisää',
      less: 'vähemmän',
      itinerary: {
        title: 'Aikataulu',
        event: 'Tapahtuma',
        trip: 'Tilaus',
        route: 'Reitti',
        address: 'Osoite',
        load: 'Kuorma',
        break: 'Tauko',
        mins: 'min',
        shiftStart: 'Vuoron aloitus',
        shiftEnd: 'Vuoron lopetus',
        export: 'Vie tiedostoon',
        stopPerform: {
          title: 'Oletko varma?',
          text: 'Merkitäänkö pysäkki ajetuksi?',
          yes: 'Kyllä',
          no: 'Ei'
        },
        stopNoShow: {
          title: 'Oletko varma?',
          text: 'Merkitäänkö tilaus hukka-ajoksi?',
          yes: 'Kyllä',
          no: 'Ei'
        }
      }
    },
    messageDetails: {
      title: 'Viesti',
      sendTime: 'Lähetetty',
      expirationTime: 'Vanhenee',
      message: 'Viesti',
      vehicles: 'Autot'
    },
    breakList: {
      vehicle: 'Auto',
      start: 'Alku',
      end: 'Loppu',
      duration: 'Kesto',
      removeConfirm: {
        title: 'Oletko varma?',
        text: 'Olet poistamassa taukoa autolta {{vehicleNumber}}, oletko varma?',
        yes: 'Kyllä',
        no: 'Peruuta'
      },
      errors: {
        add: {
          'vehicle has no shift for the given time period': 'Autolla ei ole ajovuoroa annettuna aikana.',
          'break_length_too_large - VehicleBreak.length': 'Tauon kesto ylittää annetun aikavälin.',
          invalid_added_break_shift_route_chain: 'Tauon luonti epäonnistui, tarkista aikaväli.'
        },
        remove: {
          break_not_found: 'Taukoa ei voida poistaa enää.',
          no_break_id: 'Taukoa ei voida poistaa enää.'
        }
      }
    },
    shiftList: {
      vehicle: 'Auto',
      start: 'Alkaa',
      end: 'Loppuu',
      empty: 'Ei tuloksia. Hae kirjoittamalla autonumero.',
      vehiclesHint: 'Valitse autot listasta tai kirjoittamalla esimerkiksi: "1,2-5,6" tai "2-5" tai "*" nähdäksesi kaikki',
      copy: {
        title: 'Kopioi ajovuoro',
        chooseDates: 'Valitse päivät',
        submit: 'Kopioi'
      },
      removeConfirm: {
        title: 'Oletko varma?',
        text: 'Olet poistamassa ajovuora autolta {{vehicleNumber}}, oletko varma?',
        yes: 'Kyllä',
        no: 'Peruuta'
      },
      errors: {
        add: {
          unknown: 'Uutta ajovuoroa ei saatu lisättyä.',
          'shift start time is later than end time': 'Ajovuoron alkuaika on myöhäisempi kuin loppuaika.',
          vehicle_overlapping_shifts: 'Autolla {{vehicle}} on päällekkäisiä ajovuoroja ajalle {{startTime}} - {{endTime}}.',
          'shift_invalid_start_end - VehicleShift.end': 'Ajovuorolla laittomat alku- ja loppuajat',
          add_shift_routes_no_shift: 'Autolla on jo reittejä ilman ajovuoroa. Poista reitit ensin.',
          'vehicle has already shift for the given time period': 'Autolla ei ole ajovuoroa annettuna aikana.'
        },
        update: {
          'shift start time is later than end time': 'Ajovuoron alkuaika on myöhäisempi kuin loppuaika.',
          vehicle_overlapping_shifts: 'Autolla on päällekkäisiä ajovuoroja.',
          vehicle_shift_has_routes: 'Autolla on ajoa joka estää muutoksen.',
          invalid_updated_shift_route_chain: 'Autolla on ajoa joka estää muutoksen.',
          "shift's existing breaks fall outside of new shift times": 'Ajovuoron tauot eivät mahdu uudelle aikavälille'
        },
        remove: {
          vehicle_shift_has_routes: 'Autolla on ajoa poistettavassa ajovuorossa.',
          shift_not_found: 'Vanhoja ajovuoroja ei voida poistaa.',
          shift_not_empty: 'Autolla on ajoa tai taukoja ajovuorossa.'
        },
        copy: {
          loadFailed: 'Kopioitavaa ajouvoro on poistunut palvelimelta'
        },
        addBreak: {
          unknown: 'Tauon automaattinen lisääminen epäonnistui. Lisää tauko manuaalisesti.'
        }
      }
    },
    map: {
      vehicleDetails: {
        title: 'Auto {{vehicleNumber}}',
        locationTime: 'Viimeisin sijainti'
      }
    },
    notification: {
      newTrip: 'Uusi tilaus.',
      newTrips: '{{newTrips}} uutta tilausta.',
      tripLate: 'Tilaus {{tripId}} myöhästyy!',
      vehicleAbsent: 'Auto {{vehicleNumber}} ei ajossa!',
      dispatchFail: 'Autonvalinta epäonnistui reitille {{routeId}}',
      routeCreateOk: 'Matkojen yhdistäminen onnistui',
      routeCreateFail: 'Matkoja ei voitu yhdistää',
      errorOccurred: 'Tapahtui virhe'
    },
    commonAttributes: {
      title: 'Attribuutit',
      1600: 'Testiauto',
      1605: 'Lastenistuin',
      1606: 'Vauvan turvakaukalo',
      1619: 'Tila-auto',
      1601: 'Farmari',
      1618: 'Henkilöauto',
      1614: 'Paratransit',
      1612: 'Matalalattia',
      1504: 'Lemmikit sallittu',
      1621: 'Ei tupakoitu',
      1613: 'Paarivarustus',
      1616: 'Porraskiipijä',
      1628: 'Etupenkki',
      1615: 'Pyörätuolihissi',
      1690: 'Alkometri',
      1627: 'Sähköpyörätuoli',
      1632: 'Monta pyörätuolia',
      1636: 'Auton pyörätuoli',
      9001: 'Pisarasuoja',
      1506: 'Naiskuski',
      1507: 'Mieskuski'
    },
    commonCapacities: {
      title: 'Kapasiteetit',
      ambulatory: 'Matkustaja',
      wheelchair: 'Pyörätuoli',
      luggage: 'Matkalaukku',
      'assistive-device': 'Apuväline',
      stretcher: 'Paarivarustus',
      'front-seat': 'Etupenkki',
      running: 'Nopea matkustaja'
    },
    dispatchStatus: {
      notstarted: 'Autonvalintaa ei aloitettu',
      started: 'Autonvalinta käynnissä',
      paused: 'Autonvalinta pysäytetty',
      failed: 'Autonvalinta epäonnistui',
      offering: 'Tarjous autolla',
      accepted: 'Tarjous hyväksytty',
      rejected: 'Tarjous hylätty'
    },
    offer: {
      title: 'Tarjoukset',
      titleAccepted: 'Hyväksytyt tarjoukset',
      titleCandidates: 'Kandidaattitarjoukset',
      column: {
        time: 'Aika',
        vehicle: 'Auto',
        assignedVehicle: 'Valittu',
        candidates: 'Kandidaatit',
        route: 'Reitti',
        result: 'Tulos',
        reason: 'Syy',
        rank: 'Sija'
      },
      status: {
        accepted: 'Hyväksytty',
        rejected: 'Hylätty'
      },
      reason: {
        'ds-all-suitable-vehicles-rejected': 'ds-all-suitable-vehicles-rejected',
        'vg-vehicle-not-found': 'vg-vehicle-not-found'
      }
    },
    action: {
      schedule: 'Reititä tilaus',
      unschedule: 'Poista reitiltä',
      unscheduleRoute: 'Pura reitti',
      createRoute: 'Yhdistä reitiksi',
      deleteTrip: 'Peru tilaus',
      startDispatch: 'Aloita autonvalinta',
      cancelDispatch: 'Pysäytä autonvalinta',
      assignVehicle: 'Valitse auto reitille',
      unassignVehicle: 'Poista auto reitiltä',
      findCandidates: 'Näytä autovaihtoehdot',
      selectedCount: '{{count}} kpl',
      performStopOk: 'Merkitse suoritetuksi',
      performStopNoShow: 'Merkitse Hukka-ajoksi'
    },
    actionValidation: {
      noSelection: 'tilausta/reittiä ei ole valittu',
      alreadyStarted: 'reitti on jo alkanut',
      alreadyScheduled: 'tilaus on jo reititetty',
      alreadyUnscheduled: 'tilausta ei ole reititetty',
      alreadyAssigned: 'auto on jo valitttu',
      alreadyUnassignedTrip: 'tilauksella ei ole autoa',
      alreadyUnassignedRoute: 'reitillä ei ole autoa',
      unscheduleFirst: 'tilaus on jo reititetty',
      onlyOneAssignment: 'vain yksi tilaus/reitti saa olla valittuna',
      tripWithoutRoute: 'tilausta ei ole reititetty'
    },
    dispatching: {
      option: {
        'take-next': 'Alkuperäinen välitysaikataulu',
        noop: 'Älä tee mitään',
        'cancel-route': 'Peru reitti välityksestä'
      },
      failureAction: 'Jatkotoimenpide',
      duration: 'Kesto (minuutteina)',
      excludedVehicles: 'Poissuljetut autot',
      start: 'Aloita',
      cancel: 'Peruuta',
      errors: {
        durationRequired: 'Kesto annettava'
      }
    },
    candidatesDetails: {
      title: 'Autovaihtoehdot reitille {{routeId}}',
      roundTitle: 'Autonvalintakierros {{dispatchRound}} (ryhmä {{priorityGroup}})',
      number: 'Auto',
      distance: 'Etäisyys',
      startTime: 'Aloitusaika',
      assigning: 'Asetetaan reitti autolle',
      errors: {
        unableToAssign: 'Virhe reitin asettamisessa autolle'
      }
    },
    label: {
      selectVehicle: 'Valitse auto'
    },
    stopPerform: {
      errors: {
        'Network request failed': 'Verkkoyhteys ei toimi'
      }
    },
    trip: {
      deleteReason: {
        dispatcher: 'Välittäjä',
        customer: 'Asiakas',
        'no-vehicle': 'Ei autoa',
        'double-booking': 'Tuplatilaus',
        'no-show': 'Hukka-ajo'
      },
      consignee: 'Luovutettava vastaanottajalle',
      sharingForbidden: 'Yhdistely kielletty',
      mobileApp: 'Mobiilitilaus'
    },
    readOnlyData: {
      title: 'Ylivuoto',
      attribute: 'YV'
    },
    customerLocale: {
      tuomi: {
        tripDetails: {
          excessPrice: 'Asiakasmaksu'
        }
      }
    }
  };
});