define("commander/utils/i18n/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  // Default string builder for i18n missing translations
  function _default(locale, key, context) {
    var values = Object.keys(context).map(function (key) {
      return context[key];
    });
    return key + ': ' + values.join(', ');
  }
});